// Django Rest Api connetion Utility

import axios from 'axios'
import config from "./config";

const baseURL = `${config.DJANGO_URL}`

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 60000,
    headers: {
        'Authorization': localStorage.getItem('access_token') ? "Bearer " + localStorage.getItem('access_token') : '',
        'Content-Type': 'application/json',
        'accept': 'application/json'
    }
});

export default axiosInstance