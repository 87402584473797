import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./NoteInfo.css";
import { CKEditor } from 'ckeditor4-react';
import ToggleSwitch from "../components/ToggleSwitch";
import TableDateRange from "./TableDateRange";
import * as dateFns from "date-fns";

export default function NoteInfo() {
  const file = useRef(null);
  const { id } = useParams();
  const history = useNavigate();
  const [note, setNote] = useState(null);
  const [content, setContent] = useState("");
  const [subject, setSubject] = useState("");
  const [is_top, setIs_top] = useState(false);
  const [is_publish, setIs_publish] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [is_popup, setIs_popup] = useState(false);
  const [s_date, setS_date] = useState(new Date());
  const [e_date, setE_date] = useState(new Date());

  const onSwitchActionTop = () => {
    setIs_top(!is_top);
    //console.log(isSwitchOnService);
  };

  const onSwitchActionPublish = () => {
    setIs_publish(!is_publish);
    //console.log(isSwitchOnPrivate);
  };

  const onSwitchActionPopup = () => {
    setIs_popup(!is_popup);
    //console.log(isSwitchOnPrivate);
  };

  useEffect(() => {
      try {
        axiosInstance.get(`/robotaxi/note/${id}/`).then(function(response) {
          //console.log(response.data);
          const note = response.data;
          const { content } = note;
          setContent(content);
          setSubject(note.subject);
          setIs_publish(note.is_publish);
          setIs_top(note.is_top);
          setIs_popup(note.is_popup);
          setS_date(new Date(note.s_date));
          setE_date(new Date(note.e_date));
          setNote(note);
        }).catch(function (error) {
          //console.log(error);
          if (error && error.response && error.response.status) {
            if (error.response.status === 444) {
              onError(error.response.data.message);
            } else {
              onError(error);
            }
          } else {
            onError(error);
          }

        });
      } catch (e) {
        onError(e);
      }
  }, [id]);

function validateForm() {
  return content.length > 0;
}

function formatFilename(str) {
  return str.replace(/^\w+-/, "");
}

async function handleFileChange(event) {
  file.current = event.target.files;
  //console.log(file.current);
  // Loop through files
  setFileNames([]);
  //for (let i = 0; i < file.current.length; i++) {
  //async 인경우에 병렬로 처리하기 위한 방법
  for ( const item of file.current ) {
    let formData = new FormData();
    formData.append("file_name", item);
    //axiosInstance.post(`${config.DJANGO_URL}/fileupload/`, formData ).then(function(response) {
      //console.log(response.data);
    axiosInstance.post('/fileupload/', formData ).then(function(response) {
      setFileNames(fileNames => [...fileNames, response.data]);
    }).catch(function (error) {
      //console.log(error);
      if ( error.response.status === 444 ) {
        onError(error.response.data.message);
      } else {
        onError(error);
      }
      setIsLoading(false);
    });
  }
}

function saveNote(note) {
  axiosInstance.put(`/robotaxi/note/${id}/`, note).then(function(response) {
    return;
  }).catch(function (error) {
    //console.log(error);
    if (error && error.response && error.response.status) {
      if (error.response.status === 444) {
        onError(error.response.data.message);
      } else {
        onError(error);
      }
    } else {
      onError(error);
    }
    setIsLoading(false);
  }); 
}

function deleteAttach(oldfile) {
  //delete existing attachfile
  axiosInstance.delete(`/fileupload/${oldfile}/`).then(function(response) {
    return;
  }).catch(function (error) {
    //console.log(error);
    if (error && error.response && error.response.status) {
      if (error.response.status === 444) {
        onError(error.response.data.message);
      } else {
        onError(error);
      }
    } else {
      onError(error);
    }
  });
}

function createNote(fileArray) {
  //console.log(fileArray);
  axiosInstance.post('/robotaxi/notes/', {
    subject: subject,
    content: content,
    is_publish: is_publish,
    is_top: is_top,
    is_popup: is_popup,
    s_date: dateFns.format(s_date,"yyyy-MM-dd'T'00:00:00"),
    e_date: dateFns.format(e_date,"yyyy-MM-dd'T'00:00:00"),
    filemodels: fileArray
  }).then(function(response) {
    history("/notes");
  }).catch(function (error) {
    //console.log(error);
    if (error && error.response && error.response.status) {
      if (error.response.status === 444) {
        onError(error.response.data.message);
      } else {
        onError(error);
      }
    } else {
      onError(error);
    }
    setIsLoading(false);
  }); 
}

async function handleSubmit(event) {
  let attachment;

  event.preventDefault();

  if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
    alert(
      `Please pick a file smaller than ${
        config.MAX_ATTACHMENT_SIZE / 1000000
      } MB.`
    );
    return;
  }

  setIsLoading(true);

  try {
    if (file.current) {
      var fileArray = [];
      fileNames.map( (data, index) => {
        var fileid = {};
        fileid.id = data.id
        fileArray.push({...fileid});
      })
      // arraylist 생성 필요 "filemodels": [{"id": "2"},{"id": "3"},{"id": "4"}]
      // axios 는 async 함수로 배열처리시에 지연이 발생소지가 있어 arraylist생성이 바로 안됨.
      // 따라서 파일선택시에 업로드 처리하고 생성/수정 버튼 클릭시에 노트에 파일 연결작업을 진행함.
      if (fileArray) {
        deleteNote();
        createNote(fileArray);
      }
    } else {
      attachment = note.filemodels;
      saveNote({
        subject : subject,
        content : content,
        is_publish : is_publish,
        is_top : is_top,
        is_popup : is_popup,
        s_date : dateFns.format(s_date,"yyyy-MM-dd'T'00:00:00"),
        e_date : dateFns.format(e_date,"yyyy-MM-dd'T'00:00:00"),
        filemodels: attachment,
      });
    }
    
    if (file.current && note.filemodel != null) {
      //delete existing attachfile
      deleteAttach(note.filemodels);
    }
    
    history("/notes");
  } catch (e) {
    onError(e);
    setIsLoading(false);
  }
}

async function deleteNote() {
  return axiosInstance.delete(`/robotaxi/note/${id}/`, note).then(function(response) {
    return;
  }).catch(function (error) {
    //console.log(error);
    if (error && error.response && error.response.status) {
      if (error.response.status === 444) {
        onError(error.response.data.message);
      } else {
        onError(error);
      }
    } else {
      onError(error);
    }
    setIsLoading(false);
  }); 
}

async function handleDelete(event) {
  event.preventDefault();

  const confirmed = window.confirm(
    "Are you sure you want to delete this note?"
  );

  if (!confirmed) {
    return;
  }

  setIsDeleting(true);

  try {
    await deleteNote();
    
    if (note.filemodels != null) {
      //delete existing attachfile
      note.filemodels.map((data,index) => {
        deleteAttach(data.id);
      })
    }
    
    history("/notes");
  } catch (e) {
    onError(e);
    setIsDeleting(false);
  }
}

function onEditorChange( evt ) {
  setContent( evt.editor.getData() );
}

function onChangeStartDate(event) {
  setS_date(event);
}

function onChangeEndDate(event) {
  setE_date(event);
}

return (
  <div className="NoteInfo">
    {note && (
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="subject">
          <Form.Label><span><i class="fas fa-angle-double-right"></i> 제목</span></Form.Label>
          <Form.Control
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="subject">
          <Form.Label><span><i class="fas fa-angle-double-right"></i> 처리지침</span></Form.Label>
          <Container>
            <Row xs="auto">
              <Col>
                <ToggleSwitch label="공개" isChecked={is_publish} onChange={onSwitchActionPublish}/>
              </Col>
              <Col>
                <ToggleSwitch label="TOP" isChecked={is_top} onChange={onSwitchActionTop}/>
              </Col>
            </Row>
            <Row xs="auto">
              <Col>
                <ToggleSwitch label="팝업" isChecked={is_popup} onChange={onSwitchActionPopup}/>
              </Col>
              <Col>
                <TableDateRange
                    wrapperClassName="datepicker"
                    selectedStartDate={s_date}
                    onChangeStartDate={onChangeStartDate} 
                    selectedEndDate={e_date}
                    onChangeEndDate={onChangeEndDate} 
                />
              </Col>
            </Row>
          </Container>
        </Form.Group>
        <Form.Group controlId="content">
          <Form.Label><span><i class="fas fa-angle-double-right"></i> 내용</span></Form.Label>
          <CKEditor 
            initData={content} 
            onChange={onEditorChange}
          />
        </Form.Group>
        <hr/>
        <Form.Group controlId="file">
          <Form.Label><span><i class="fas fa-angle-double-right"></i> Attachment</span></Form.Label>
          {note.filemodels && (
            note.filemodels.map( (data, index) => {
              return ( 
                <>
                  <p>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${config.DJANGO_URL}` + data.file_save_name}
                    >
                      {index+1}{"   "}{data.id}{data.file_origin_name}
                    </a>
                  </p>
                </>);
            })
          )}
          <Form.Control onChange={handleFileChange} type="file" />
        </Form.Group>
        <LoaderButton
          block
          size="lg"
          type="submit"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Save
        </LoaderButton>
        <LoaderButton
          block
          size="lg"
          variant="danger"
          onClick={handleDelete}
          isLoading={isDeleting}
        >
          Delete
        </LoaderButton>
      </Form>
    )}
    <div>
      <br /><br /><br />
    </div>
  </div>
);

}