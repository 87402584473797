import React, { useState, useMemo, useEffect } from "react";
import "./App.css";
import AppRoutes from "./AppRoutes";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, Navbar, NavItem, NavDropdown, Button, Toast, Badge } from "react-bootstrap";
import { AppContext } from "./libs/contextLib";
import { useNavigate } from "react-router-dom";
import axiosInstance from "./axiosApi";
import LangDropdown from "./components/LangDropdown";
import { getToken, onMessageListener, defaultPayload, Payload } from "./firebase";
import axios from 'axios'
import Logo from './images/autodrive_logo.jpg';
import { useTranslation } from 'react-i18next';
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { BsFillAlarmFill } from "react-icons/bs";
import config from "./config";
import { differenceInMinutes } from "date-fns";
import SubNavigation from "./components/SubNavigation";
import { IAppContext, User, defaultUser, Route, defaultRoute, Station, defaultStation, RouteMap, defaultRouteMap, Car, defaultCar } from "./types/app";

function App():JSX.Element {
    
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [user, setUser] = useState<User>(defaultUser);
  const history = useNavigate();
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState('en');

  const [routeAppContext, setRouteAppContext] = useState<Route>(defaultRoute);
  const [strStationAppContext, setStrStationAppContext] = useState<Station>(defaultStation);
  const [currentLocation, setCurrentLocation] = useState('/');
  const [routeMap, setRouteMap] = useState<RouteMap>(defaultRouteMap);
  const [carAppContext, setCarAppContext] = useState<Car>(defaultCar);
  
  const providerValue = useMemo(() => ({
        isAuthenticated, userHasAuthenticated,
        user, setUser,
        routeAppContext, setRouteAppContext,
        strStationAppContext, setStrStationAppContext,
        currentLocation, setCurrentLocation,
        routeMap, setRouteMap,
        carAppContext, setCarAppContext,
  }), [isAuthenticated, user, routeAppContext, currentLocation, routeMap, carAppContext]);
  
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [isTokenFound, setTokenFound] = useState(false);
  const [payload, setPayload] = useState<Payload>(defaultPayload);
  const [lapseMin, setLapseMin] = useState("");
  getToken(setTokenFound);

  onMessageListener().then((payload:any) => {
      if (user !== null) {
        setShow(true);
    } else {
        setShow(false);
    }
    setNotification({title: payload.notification.title, body: payload.notification.body})
    setPayload(payload);
    setLapseMin("just now");
    //console.log(payload);
  }).catch(err => console.log('failed: ', err));
  
  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    axios.post(`${config.DJANGO_URL}/accounts/token/verify/`, {
                token: localStorage.getItem("access_token"),
    }).then(function(response) {
        userHasAuthenticated(true);
        setUser(JSON.parse(localStorage.getItem("access_user")||''));
    }).catch(function (error) {
        //console.log(error);
    });
    setIsAuthenticating(false);
  }
  
  function handleLogout() {
    // add sign out login...
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_user');
    userHasAuthenticated(false);
    setUser(defaultUser);
    history("/login");
  }
  
  const onChange = (event:any) => {
    //console.log(event);
    setSelectedValue(event.target.value );
  }
  
  const handleSelect = (eventKey:any) => {
    if (eventKey === 'en' || eventKey === 'kr') {
      //console.log('A key was pressed', eventKey);
      setSelectedLang(eventKey);
      i18n.changeLanguage(eventKey);
    }
  }
  
  const onClickToast = () => {
      if (user !== null) {
        setShow(true);
      } else {
        setShow(false);
      }
      
      if (payload !== null) {
        setLapseMin(differenceInMinutes(new Date(), new Date(payload.data.crtdate)).toString());
      }
  }
  
  return (
    <>
    { !isAuthenticating && (
    <div className="App container-fluid">
      <Toast bg="dark" onClose={() => setShow(false)} show={show} delay={5000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{notification.title}</strong>
            <small>{lapseMin}{lapseMin === 'just now' ? '' : '분전'}</small>
          </Toast.Header>
          <Toast.Body className="text-white">{notification.body}</Toast.Body>
      </Toast>
      <Navbar collapseOnSelect  expand="md" className="navbar-dark bg-dark px-2">
        <LinkContainer to="/">
          <Navbar.Brand className="font-weight-bold">
            <img
            src={Logo}
            width="30"
            height="30"
            className="d-inline-block"
            />
            {'    '} {t('HOME_TITLE')} {'   '} 
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          
          <Nav activeKey={window.location.pathname} onSelect={handleSelect}>
          { ((user !== null) ? user.is_staff : false) ? (
          <>
            {isAuthenticated ? (
              <>
                <LinkContainer to="/drivingcontrol">
                  <Nav.Link>{t('FMS_MAIN')}</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/drivingsummary">
                  <Nav.Link>{t('FMS_HISTORY')}</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/settings">
                  <Nav.Link>{t('SETTINGS')}</Nav.Link>
                </LinkContainer>
                <Nav.Link onClick={handleLogout}>LOGOUT</Nav.Link>
              </>
            ) : (
            <>
              <LinkContainer to="/signup">
                <Nav.Link>{t('SIGNUP')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/login">
                <Nav.Link>{t('LOGIN')}</Nav.Link>
              </LinkContainer>
            </>
            )}
          </>
          ) : (
          <>
            {isAuthenticated ? (
              <>
                <LinkContainer to="/drivingcontrol">
                  <Nav.Link>{t('FMS_MAIN')}</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/drivingsummary">
                  <Nav.Link>{t('FMS_HISTORY')}</Nav.Link>
                </LinkContainer>
                <Nav.Link onClick={handleLogout}>LOGOUT</Nav.Link>
              </>
            ) : (
            <>
              <LinkContainer to="/signup">
                <Nav.Link>{t('SIGNUP')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/login">
                <Nav.Link>{t('LOGIN')}</Nav.Link>
              </LinkContainer>
            </>
            )}
          </>
          )}
            <LangDropdown/>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      { isAuthenticated ? <SubNavigation currentLocation={currentLocation} /> : "" }
      {/*
      <Navbar fixed="bottom" expand="md" className="navbar-dark bg-light px-2">
        <div className="col-sm-4">
        </div>
        <div className="col-sm-4">
          <div class="text-center">
            E-mail: <a href="mailto:raxi@sonnet.ai">raxi@sonnet.ai</a> <br/>
            <a href="http://sonnet.ai/"> sonnet.ai</a> ⓒ 2021-{new Date().getFullYear()}, {t('VERSION')}
          </div>
        </div>
        <div className="col-sm-4">
        </div>
      </Navbar>
      */}
      <AppContext.Provider value={providerValue}>
        <AppRoutes />
      </AppContext.Provider>
    </div>
   )
   }</>
  );
}

export default App;

