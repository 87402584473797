import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Stack } from 'react-bootstrap';
import { useParams, useNavigate, Link } from "react-router-dom";
import axiosInstance from "../axiosApi";
import { onError } from "../libs/errorLib";
import moment from "moment";
import 'moment/locale/ko';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useTranslation } from 'react-i18next';
import ToggleSwitch from "../components/ToggleSwitch";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { IconContext } from "react-icons";
import TableDateTimeRange from "./TableDateTimeRange";
import * as dateFns from "date-fns";
import LoadingSpinner from "../components/LoadingSpinner";
import { useAppContext } from "../libs/contextLib";
import DropdownSelector from "../components/DropdownSelector";

function DrivingData() {
  const [bookingCar, setBookingCar] = useState([]);
  const history = useNavigate();
  const { t } = useTranslation();
  const [stateOptions, setStateOptions] = useState([
    { name: t('INGSTATUS_001'),id: "A"},
    { name: t('INGSTATUS_002'),id: "B"},,
    { name: t('INGSTATUS_003'),id: "C"},
    { name: t('INGSTATUS_004'),id: "D"},
    { name: t('INGSTATUS_005'),id: "E"},
    { name: t('INGSTATUS_006'),id: "F"},
    { name: t('INGSTATUS_007'),id: "G"},
    { name: t('INGSTATUS_008'),id: "H"},
  ]);
  const [startDate, setStartDate] = useState(new Date()); 
  const [endDate, setEndDate] = useState(new Date()); 
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { routeAppContext } = useAppContext();
  const [routeOptions, setRouteOptions] = useState([]);
  const [carOptions, setCarOptions] = useState([]);
  
  const columns = [
    {
        dataField: "vehicle_id",
        text: '차량id',
    },
    {
        dataField: "recordtime",
        text: '년월일시분초'
    },
    {
        dataField: "distance_driven",
        text: '이동거리'
    },
    {
        dataField: "latitude",
        text: '위도'
    },
    {
        dataField: "longitude",
        text: '경도'
    },
    {
        dataField: "speed",
        text: '속도'
    },
    {
      dataField: "heading",
      text: 'heading'
    },
    {
      dataField: "vehicleAccelerationX",
      text: 'vehicleAccelerationX'
    },
    {
      dataField: "vehicleAccelerationY",
      text: 'vehicleAccelerationY'
    },
    {
      dataField: "autodriveModeStatus",
      text: 'autodriveModeStatus'
    },
    {
      dataField: "vehicleAccelerationPedalPosition",
      text: 'vehicleAccelerationPedalPosition'
    },
    {
      dataField: "vehicleBreakPedalPosition",
      text: 'vehicleBreakPedalPosition'
    },
    {
      dataField: "vhicleSteerControlCommand",
      text: 'vhicleSteerControlCommand'
    },
    {
      dataField: "vehicleSteerAngle",
      text: 'vehicleSteerAngle'
    },
    {
      dataField: "vehicleSteerTorque",
      text: 'vehicleSteerTorque'
    },
    {
      dataField: "vehicleGearPosition",
      text: 'vehicleGearPosition'
    },
    {
      dataField: "autodrivingAccelerationCommand",
      text: 'autodrivingAccelerationCommand'
    },
    {
      dataField: "autodrivingTargetSpeed",
      text: 'autodrivingTargetSpeed'
    },
  ];
  
  const MyExportData = (props) => {
    return (
      <IconContext.Provider
          value={{style: { color: '#A4BEF3', fontSize: '30px' }}}
        >
          <div>
            <FaCloudDownloadAlt onClick={ props.onClick } />
            {" "}To {props.type}
          </div>
      </IconContext.Provider>
        //<button className="btn btn-success" onClick={ handleClick }>다운로드 CSV</button>
    );
  };

  useEffect(() => {
    onLoad();
  }, [startDate, endDate]);
  
  async function onLoad() {
    setBookingCar([]);
    //console.log(dateFns.format(startDate,"yyyy-MM-dd'T'HH:mm:ss"));
    //console.log(dateFns.format(dateFns.add(endDate, { days: 1 }),"yyyy-MM-dd'T'HH:mm:ss"));
    try {
      setIsLoading(true);
      axiosInstance.get('/robotaxi/drivedata/', {
          params:{ 
              s_date:dateFns.format(startDate,"yyyy-MM-dd'T'HH:mm:ss"),
              e_date:dateFns.format(dateFns.add(endDate, { days: 1 }),"yyyy-MM-dd'T'HH:mm:ss"),
          }
      }).then(function(response) {
          //console.log(response.data[0].carinfo.data.latitude);
          let filename;

          response.data.map( (data_row) => {
              let book = {};
             
              book['vehicle_id'] = data_row.carinfo.data.vehicle_id
              book['recordtime'] = data_row.carinfo.data.time.year + '-' + data_row.carinfo.data.time.month.toString().padStart(2, '0') + '-'
              + data_row.carinfo.data.time.day + ' ' + data_row.carinfo.data.time.hour + ':'
              + data_row.carinfo.data.time.min + ':' + data_row.carinfo.data.time.sec + ' '
              + data_row.carinfo.data.time.nano_sec
              book['distance_driven'] = data_row.carinfo.data.distance_driven
              book['latitude'] = data_row.carinfo.data.latitude
              book['longitude'] = data_row.carinfo.data.longitude
              book['speed'] = data_row.carinfo.data.speed
              book['heading'] = data_row.carinfo.data.heading
              book['vehicleAccelerationX'] = data_row.carinfo.data.vehicleAccelerationX
              book['vehicleAccelerationY'] = data_row.carinfo.data.vehicleAccelerationY
              book['autodriveModeStatus'] = data_row.carinfo.data.autodriveModeStatus
              book['vehicleAccelerationPedalPosition'] = data_row.carinfo.data.vehicleAccelerationPedalPosition
              book['vehicleBreakPedalPosition'] = data_row.carinfo.data.vehicleBreakPedalPosition
              book['vhicleSteerControlCommand'] = data_row.carinfo.data.vhicleSteerControlCommand
              book['vehicleSteerAngle'] = data_row.carinfo.data.vehicleSteerAngle
              book['vehicleSteerTorque'] = data_row.carinfo.data.vehicleSteerTorque
              book['vehicleGearPosition'] = data_row.carinfo.data.vehicleGearPosition
              book['autodrivingAccelerationCommand'] = data_row.carinfo.data.autodrivingAccelerationCommand
              book['autodrivingTargetSpeed'] = data_row.carinfo.data.autodrivingTargetSpeed
              setBookingCar(bookingCar => [...bookingCar, book])
              
              filename = '차량운행기록_데이터기간('.concat(dateFns.format(startDate,"yyyy-MM-dd"),'~',dateFns.format(endDate,"yyyy-MM-dd"),')')
          });
          setFileName(filename);
          setIsLoading(false);
        
      }).catch(function (error) {
        if (error && error.response && error.response.status) {
          if (error.response.status === 444) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
        } else {
          onError(error);
        }
        setIsLoading(false);
      }); 
    } catch (e) {
      onError(e);
    }
  }

  function onSelectRow(row, isSelected, e) {
    if (isSelected) {
        console.log(`You just selected '${row['s_date']}'`);
    }
  };
  
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group" role="group">
      {
        options.map((option) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={ option.text }
              type="button"
              onClick={ () => onSizePerPageChange(option.page) }
              className={ `btn ${isSelect ? 'btn-dark' : 'btn-secondary'}` }
            >
              { option.text }
            </button>
          );
        })
      }
    </div>
  );

  const options = {
    sizePerPageRenderer
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob(["\ufeff"+data], { type: fileType + '; charset=utf-8'})
  
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }
  
  const exportToJson = e => {
    e.preventDefault()
    downloadFile({
      data: JSON.stringify(bookingCar),
      fileName: 'users.json',
      fileType: 'text/json',
    })
  }
  
  const exportToCsv = e => {
    e.preventDefault()
  
    // Headers for each column
    let headers = ['vehicle_id,recordtime,distance_driven,latitude,longitude,speed,heading,vehicleAccelerationX,vehicleAccelerationY,autodriveModeStatus,vehicleAccelerationPedalPosition,vehicleBreakPedalPosition,vhicleSteerControlCommand,vehicleSteerAngle,vehicleSteerTorque,vehicleGearPosition,autodrivingAccelerationCommand,autodrivingTargetSpeed']
  
    // Convert users data to a csv
    let booksCsv = bookingCar.reduce((acc, book) => {
      const { vehicle_id,recordtime,distance_driven,latitude,longitude,speed,heading,vehicleAccelerationX,vehicleAccelerationY,autodriveModeStatus,vehicleAccelerationPedalPosition,vehicleBreakPedalPosition,vhicleSteerControlCommand,vehicleSteerAngle,vehicleSteerTorque,vehicleGearPosition,autodrivingAccelerationCommand,autodrivingTargetSpeed } = book
      acc.push([vehicle_id,recordtime,distance_driven,latitude,longitude,speed,heading,vehicleAccelerationX,vehicleAccelerationY,autodriveModeStatus,vehicleAccelerationPedalPosition,vehicleBreakPedalPosition,vhicleSteerControlCommand,vehicleSteerAngle,vehicleSteerTorque,vehicleGearPosition,autodrivingAccelerationCommand,autodrivingTargetSpeed].join(','))
      return acc
    }, [])

    downloadFile({
      data: [...headers, ...booksCsv].join('\n'),
      fileName: fileName.concat('.csv'),
      fileType: 'text/csv',
    })
  }

  const exportToTxt = e => {
    e.preventDefault()
  
    // Headers for each column
    let headers = ['vehicle_id|recordtime|distance_driven|latitude|longitude|speed|heading|vehicleAccelerationX|vehicleAccelerationY|autodriveModeStatus|vehicleAccelerationPedalPosition|vehicleBreakPedalPosition|vhicleSteerControlCommand|vehicleSteerAngle|vehicleSteerTorque|vehicleGearPosition|autodrivingAccelerationCommand|autodrivingTargetSpeed']
  
    // Convert users data to a csv
    let booksCsv = bookingCar.reduce((acc, book) => {
      const { vehicle_id,recordtime,distance_driven,latitude,longitude,speed,heading,vehicleAccelerationX,vehicleAccelerationY,autodriveModeStatus,vehicleAccelerationPedalPosition,vehicleBreakPedalPosition,vhicleSteerControlCommand,vehicleSteerAngle,vehicleSteerTorque,vehicleGearPosition,autodrivingAccelerationCommand,autodrivingTargetSpeed } = book
      acc.push([vehicle_id,recordtime,distance_driven,latitude,longitude,speed,heading,vehicleAccelerationX,vehicleAccelerationY,autodriveModeStatus,vehicleAccelerationPedalPosition,vehicleBreakPedalPosition,vhicleSteerControlCommand,vehicleSteerAngle,vehicleSteerTorque,vehicleGearPosition,autodrivingAccelerationCommand,autodrivingTargetSpeed].join('|'))
      return acc
    }, [])

    downloadFile({
      data: [...headers, ...booksCsv].join('\n'),
      fileName: fileName.concat('.txt'),
      fileType: 'text/plain',
    })
  }

  async function onChangeStartDate(event) {
    // 현재시간 1시간이후 부터 예약가능시간 적용
    if ( dateFns.isAfter(endDate, new Date(event)) ) {
        setStartDate(startDate => event);
    } else {
        alert("시작일자는 종료일자보다 같거나 작아야 합니다.");
        setStartDate(startDate => new Date());
    }
  }

  async function onChangeEndDate(event) {
    // 현재시간 1시간이후 부터 예약가능시간 적용
    if ( dateFns.isAfter(new Date(event), startDate) ) {
        setEndDate(endDate => event);
    } else {
        alert("종료일자는 시작일자보다 같거나 커야 합니다.");
        setEndDate(endDate => new Date());
    }
  }

  return (
  <Stack gap={3}>
      <div className="bg-light border">
        <div>
        <Container>
            <Row className="align-items-center">
                <Col>
                    <TableDateTimeRange
                        wrapperClassName="datepicker"
                        selectedStartDate={startDate}
                        onChangeStartDate={onChangeStartDate} 
                        selectedEndDate={endDate}
                        onChangeEndDate={onChangeEndDate} 
                    />
                </Col> 
            </Row>
            <Row xs="auto" className="align-items-center">
                <Col />
                <Col>
                    <MyExportData onClick={exportToCsv} type='CSV' />
                </Col>
                <Col>
                    <MyExportData onClick={exportToTxt} type='TXT' />
                </Col>
            </Row>
        </Container>
        {isLoading ? <LoadingSpinner /> : ""}
        <BootstrapTable
            bootstrap4
            keyField="id"
            data={bookingCar}         
            columns={columns}
            striped
            hover
            condensed
            bordered={ false }
            pagination={paginationFactory(options)}
        />
        </div>
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default DrivingData;