import React from "react";
import { Container, Nav, Navbar, Stack } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from 'react-i18next';
import "./SubNavigation.css";

function SubNavigationManager() {
  const { t } = useTranslation();

  return (

    <div className="bg-light border my-2">
      <Nav fill variant="pills">
        <LinkContainer to="/drivingsummary" className="nav-link me-5" style={{ cursor: 'pointer' }}>
          <strong>Raxi운행기록(일별)</strong>
        </LinkContainer>
        <LinkContainer to="/adrtreservation" className="nav-link mx-5" style={{ cursor: 'pointer' }}>
          <strong>aDRT 예약정보</strong>
        </LinkContainer>
      </Nav>
    </div>

  );
}

export default SubNavigationManager;