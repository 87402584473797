import React, { useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form, Accordion, Container, Row, Col } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Signup.css";
import axiosInstance from "../axiosApi";
import axios from 'axios'
import { useTranslation } from 'react-i18next';
import config from "../config";
import TermsOfService from "../components/TermsOfService";
import PrivatePolicy from "../components/PrivatePolicy";
import ToggleSwitch from "../components/ToggleSwitch";

export default function SignupPhone() {
  const { t } = useTranslation();
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "OK",
    phone_number: "",
    authcode: "",
  });
  const history = useNavigate();
  const [newUser, setNewUser] = useState(null);
  const { userHasAuthenticated } = useAppContext();
  const { user, setUser } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [message, setMessage] = useState(t('AUTHMSG'));
  const [isSwitchOnService, setIsSwitchOnService] = useState(false);
  const [isSwitchOnPrivate, setIsSwitchOnPrivate] = useState(false);
  const [isSwitchOnAgeLimit, setIsSwitchOnAgeLimit] = useState(false);

  const onSwitchActionService = () => {
    setIsSwitchOnService(!isSwitchOnService);
    //console.log(isSwitchOnService);
  };

  const onSwitchActionPrivate = () => {
    setIsSwitchOnPrivate(!isSwitchOnPrivate);
    //console.log(isSwitchOnPrivate);
  };

  const onSwitchActionAgeLimit = () => {
    setIsSwitchOnAgeLimit(!isSwitchOnAgeLimit);
    //console.log(isSwitchOnAgeLimit);
  };

  useEffect(() => {
    async function onLoad() {
        var IMP = window.IMP;
        IMP.init("imp82530589");

        // IMP.certification(param, callback) 호출
        IMP.certification({ // param
            merchant_uid: "ORD20180131-0000011", // 주문 번호
            m_redirect_url : "https://localhost/accounts/phone/callback/react", // 모바일환경에서 popup:false(기본값) 인 경우 필수, 예: https://www.myservice.com/payments/complete/mobile
            popup : false // PC환경에서는 popup 파라메터가 무시되고 항상 true 로 적용됨
        }, function (rsp) { // callback
            if (rsp.success) {
            // 인증 성공 시 로직,

            } else {
            // 인증 실패 시 로직,

            }
        });
    }
    
    onLoad();
  }, []);

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword &&
      isConfirm &&
      isSwitchOnService &&
      isSwitchOnPrivate &&
      isSwitchOnAgeLimit
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    if (!isConfirm) {
        axios.post(`${config.DJANGO_URL}/accounts/checksignup/`, {
                    authcode: fields.authcode,
        }).then(function(response) {
            if (response.data.access === 'ok') {
            setIsConfirm(true);
            } else {
            setMessage(t('AUTHFAILMSG'));
            }
            setIsLoading(false);
        }).catch(function (error) {
            onError(t('AUTHFAILMSG'));
            userHasAuthenticated(false);
            setIsLoading(false);
        });
    } else {
        // 휴대폰인증작업 진행
        var IMP = window.IMP;
        IMP.init("imp82530589");

        // IMP.certification(param, callback) 호출
        IMP.certification({ // param
            merchant_uid: "ORD20180131-0000011", // 주문 번호
            m_redirect_url : "https://localhost/accounts/phone/callback/react", // 모바일환경에서 popup:false(기본값) 인 경우 필수, 예: https://www.myservice.com/payments/complete/mobile
            popup : false // PC환경에서는 popup 파라메터가 무시되고 항상 true 로 적용됨
        }, function (rsp) { // callback
            console.log(rsp);
            if (rsp.success) {
            // 인증 성공 시 로직,

            } else {
            // 인증 실패 시 로직,

            }
        });
    }
  }
  
  return (
    <Stack gap={3}>
      <div className="bg-light border">
        <Nav sticky="top">
              <LinkContainer to="/signupsocial">
                  <Nav.Link>SNS {t('SIGNUP')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/signupbasic">
                  <Nav.Link>E-mail {t('SIGNUP')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/signupphone">
                  <Nav.Link>Phone {t('SIGNUP')}</Nav.Link>
              </LinkContainer>
        </Nav>
      </div>
      <div className="bg-light border">
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="authcode" size="lg">
            <Form.Label>{t('AUTHCODE')}</Form.Label>
            <Form.Control
              type="text"
              onChange={handleFieldChange}
              value={fields.authcode}
            />
          </Form.Group>
          <LoaderButton
            block
            size="lg"
            type="submit"
            variant="dark"
            isLoading={isLoading}
            disabled={isConfirm}
          >
            {t('AUTHVERIFY')}
          </LoaderButton>
        </Form>
      </div>
      <div className="bg-light border">
      {isConfirm ? (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email" size="lg">
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              value={fields.email}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group controlId="password" size="lg">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              autocomplete="off"
              value={fields.password}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group controlId="confirmPassword" size="lg">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              autocomplete="off"
              onChange={handleFieldChange}
              value={fields.confirmPassword}
            />
          <h6>{t('PASSWORDINFO')}</h6>
          </Form.Group>
          <LoaderButton
            block
            size="lg"
            type="submit"
            variant="dark"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            휴대폰 인증
          </LoaderButton>
        </Form>
      ) : (
			  <>
			  <h5>{message}</h5>
			  </>
			)}
      </div>
      <div className="bg-light border">
        <Container>
          <Row className="justify-content-md-center">
            <Col sm>
              <ToggleSwitch label="만 14세 이상입니다 동의" isChecked={isSwitchOnAgeLimit} onChange={onSwitchActionAgeLimit}/>
            </Col>
            <Col sm>
              <ToggleSwitch label="서비스이용약관 동의" isChecked={isSwitchOnService} onChange={onSwitchActionService}/>
            </Col>
            <Col sm>
              <ToggleSwitch label="개인정보이용약관 동의" isChecked={isSwitchOnPrivate} onChange={onSwitchActionPrivate}/>
            </Col>
          </Row>
        </Container>
        <Accordion alwaysOpen="False">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              서비스이용약관
            </Accordion.Header>
            <Accordion.Body>
              <TermsOfService />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              개인정보이용약관
            </Accordion.Header>
            <Accordion.Body>
              <PrivatePolicy />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div>
        <br /><br /><br />
      </div>
    </Stack>
    );
}