import { useEffect } from "react";
import L from "leaflet";
import "./AutoDriveLegend.css";
import { useAppContext } from "../libs/contextLib";
import ByLines from '../data/Techno_A2_LINK.json'

let dom_el = null;

function AutoDriveLegend({ map, carInfo }) {
  const { routeAppContext, setRouteAppContext } = useAppContext();
  const { carAppContext, setCarAppContext } = useAppContext();

  useEffect(() => {
    if (map && (routeAppContext.id == carInfo.data.route_id) && (carAppContext.vehicle_id == carInfo.data.vehicle_id)) {
      //console.log("legend view : vehicle_id" + carAppContext.vehicle_id)
      if (dom_el) {
        L.DomUtil.remove(dom_el);
      }
      const info = L.control({ position: "topleft" });

      info.onAdd = () => {
        info._div = L.DomUtil.create("div", "info");
        dom_el = info._div;
        info.update(carInfo.data);
        return info._div;
      };

      info.update = (props) => {
        info._div.innerHTML =
          "<h5>차량주행정보</h5><hr>" +
          (props
            ? "<b>vehicle_id: " + props.vehicle_id + "</b><br />" +
            "<b>speed: " + props.speed + "</b><br />" +
            "<b>latitude: " + props.latitude + "</b><br />" +
            "<b>longitude: " + props.longitude + "</b><br />" +
            "<b>heading: " + props.heading + "</b><br />" +
            "<b>autoModeStart: " + props.autoModeStart + "</b><br />" +
            "<b>trvlDist: " + props.trvlDist + "</b><br />" +
            "<b>autoTrvlDist: " + props.autoTrvlDist + "</b><br />"
            : "차량정보가 없습니다.");
      };

      info.addTo(map);
    } else {
      if (dom_el) {
        L.DomUtil.remove(dom_el);
      }
    }
  }, [map, carInfo]);

  return null;
}

export default AutoDriveLegend;