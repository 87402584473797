import React, { useCallback, useEffect, useState, Suspense, useRef, useReducer } from 'react';
import { Canvas, ThreeEvent, useFrame, MeshProps, useThree } from "@react-three/fiber";
import { Stats, OrbitControls, PerspectiveCamera, Sky, Line } from "@react-three/drei";
import * as THREE from "three";
import { Nav, Navbar, Stack, Form, Row, Col, Button } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import config from "../config";
import { propTypes } from 'react-bootstrap/esm/Image';
import css from './MapAutoDrive.module.css';

//  상태처리
enum StateActionKind {
  Distance = 'DISTANCE',
  Vehicle = 'VEHICLE',
  Axis = 'AXIS',
  LineID = 'LINEID',
}

interface State {
  distance: string;
  vehicle: string;
  axis: string;
  lineID: string;
}

interface StateAction {
  type: StateActionKind,
  payload: string,
}

const stateReducer = (prevState: State, action: StateAction): State => {
  switch (action.type) {
      case StateActionKind.Distance:
          return {
              ...prevState,
              distance: action.payload,
          };
      case StateActionKind.Vehicle:
          return {
              ...prevState,
              vehicle: action.payload,
          };
      case StateActionKind.Axis:
          return {
              ...prevState,
              axis: action.payload,
          };
      case StateActionKind.LineID:
          return {
              ...prevState,
              lineID: action.payload,
          };
      default:
          return prevState;
  }
}

const initialState = {
  distance: 'distance',
  vehicle: 'vehicle',
  axis: 'X Y',
  lineID: 'lineID',
};

// 정밀지도
interface MapHD {
  lineID: string;
  color: string;
  point: {
      x: number;
      y: number;
  }[];
}

interface MapLinePoints {
  lineID: string;
  color: string;
  points: number[];
}

const pointReducer = (prevPoint:any, action:any) => {
  let array;
  switch (action.type) {
      case 'ADD':
          array = [...prevPoint];
          array.push(action.payload);
          return array;
      case 'REMOVE':
          array = [...prevPoint];
          array.pop();
          return array;
      case 'CLEAR':
          return prevPoint = [];
      default:
          break;
  }
}

const deg2rad = (degrees:any) => degrees * (Math.PI / 180);

const Box = (props:any) => {
  const boxRef = useRef<MeshProps>();

  useFrame(() => {
    if (boxRef.current) {
      boxRef.current.rotation.x += 0.03;
      boxRef.current.rotation.y += 0.02;
    }
  });

  return (
    <mesh {...props} ref={boxRef}>
      <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
      <meshStandardMaterial metalness={0.1} attach="material" color={'#f4511e'} />
    </mesh>
  )
}

const LineGeo = (props:any) => {
  const boxRef = useRef<MeshProps>();

  useFrame(() => {
    if (boxRef.current) {
      boxRef.current.rotation.x += 0.03;
      boxRef.current.rotation.y += 0.02;
    }
  });

  return (
    <Line
      points={[
        [-20, 0, 0],
        [0, 20, 0],
        [20, 0, 0]
      ]}
      color={0xffffff}
    />
  )
}

const Sphere = (props:any) => {
  const boxRef = useRef<MeshProps>();

  useFrame(() => {
    if (boxRef.current) {
      boxRef.current.rotation.x += 0.01;
      boxRef.current.rotation.y += 0.01;
    }
  });

  return (
    <mesh {...props} ref={boxRef} position={[ 0, 0, 0 ]}>
      <sphereBufferGeometry
        attach="geometry" 
        args={[5, 22, 50]}
        smoothness={5} />
      <meshPhongMaterial
        color="darkred"
        attach="material"
        transparent
        roughness={0.1}
        metalness={0.1}
      />
    </mesh>
  )
}

const MapDrawHD = (props:any) => {
  const ref = useRef<MeshProps>();
  
  useFrame(() => {
      if (ref.current) {
      //ref.current.rotation.x += 0.03;
      //ref.current.rotation.y += 0.02;
      }
  });

  const handleMove = (event:any) => {
    if (Object.keys(event.intersections).length !== 0) {
      props.onStateChange({type:StateActionKind.LineID, payload: event.intersections[0].object.name });
    } else {
      props.onStateChange({type:StateActionKind.LineID, payload: '' });
    }
  }

  return (
      <mesh {...props} ref={ref}
        onPointerMove={handleMove}
      >
  {   
      props.data.map( (line:MapLinePoints) => {
          //console.log(line);
          return (
              <Line
                  name={line.lineID}
                  points={line.points}
                  color={line.color}
                  lineWidth={0.5}
                  dashed={false}
              />
          );        
      })
  }
  </mesh>
  )
}

const Scene = (props:any) => {
  return (
    <>
      {/*
      <gridHelper
        args={[10000, 50, "#FFFFFF", "#FFFFFF"]}
        position={[0, 0, 0]}
        rotateX={Math.PI / 2}
      />
      <axesHelper />
      */}
      <pointLight intensity={1.0} position={[5, 3, 5]} />
      <ambientLight intensity={0.5} />
      <directionalLight />
      {/*
      <Box position={[-1.2, 0, 0]} />
      <Box position={[1.2, 0, 0]} />
      <LineGeo />
      */}
      <Sphere />
      <MapDrawHD data={props.data} onStateChange={props.onStateChange} />
    </>
  );
};

const CameraController = (props:any) => {
  const ocRef = useRef<typeof THREE.OrbitControls>(null)
  const tcRef = useRef<typeof THREE.TrackballControls>(null)
  let camera = useThree((state) => state.camera)

  useEffect(() => {
    console.log('Control style changed to: ', props.controlStyle)
    if (tcRef.current && ocRef.current) {
      let p = camera.position.clone()
      if (props.controlStyle === 'orbit') {
        tcRef.current.reset()
      }
      if (props.controlStyle === 'trackball') {
        ocRef.current.reset()
      }
      camera.position.copy(p)
    }
  }, [props.controlStyle, camera])

  return (
    <>
      <OrbitControls enabled={props.controlStyle === 'orbit'} ref={ocRef} rotateSpeed={2} />
      <PerspectiveCamera enabled={props.controlStyle === 'trackball'} makeDefault postion={[0,0,500]} {...props} />
    </>
  )
}

const MapAutoDrive = (props:any) => {
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [controlStyle, setControlStyle] = useState('trackball')
  const toggleControlStyle = () => {
    if (controlStyle === 'orbit') setControlStyle('trackball')
    else setControlStyle('orbit')
  }

  const [points, dispatcher] = useReducer(pointReducer, [
    [-10, 0, 0],
    [0, 10, 0],
    [10, 0, 0]
  ]);

  const addHandler = (aPoint:any) => {
      dispatcher({type:'ADD', payload: aPoint});
  };

  // 정밀지도 (J2735 MAP 데이터 포함)
  const [mapLinePonits, setMapLinePointsDispatcher] = useReducer(pointReducer, [{"lineID":"A219AR068340", "points":[
          [-10, 0, 0],
          [0, 10, 0],
          [10, 0, 0]
      ]}
    ]);

  var map: MapHD[];

  var pointerInfo: {
    // 차선 선택
    event: PointerEvent | undefined;
    // 마우스 커서 좌표를 UTM으로 변환
    position: typeof THREE.Vector3;
    // 이전 마우스 위치
    x: number;
    y: number;
    // 마우스 휠 Z축 최고 위치
    z: number;
  } = {
    event: undefined,
    position: new THREE.Vector3(),
    x: 0,
    y: 0,
    z: 1000,
  };

  // 지도 중점 좌표
  var center: {
      x: number;
      y: number;
  } = {
      x: 0,
      y: 0,
  };

  const [state, setStateDispatcher] = useReducer(stateReducer, initialState);

  useEffect(() => {
    if (Object.keys(props.data).length !== 0) {
      drawMapHD(props.data);
    }
  
    container.current.addEventListener('pointermove', onPointerMove)
    container.current.addEventListener('pointerup', onPointerUp)
    container.current.addEventListener('mousedown', onPointerDown)
    container.current.addEventListener('keydown', handleKeyDown);

    animate();

    
  }, [props]);

  const onPointerMove = (event: PointerEvent): void => {
    pointerInfo.event = event;
    //console.log(event);
  }

  const onPointerDown = (event: MouseEvent): void => {
    console.log(event);
  }

  const onPointerUp = (event: PointerEvent): void => {
    console.log("Pointer up event");
  }

  const handleKeyDown = (event: KeyboardEvent): void => {
    console.log("key down event");
  }

  const draw = (): void => {
    if (
      undefined !== pointerInfo.event &&
      pointerInfo.x !== pointerInfo.event.screenX &&
      pointerInfo.y !== pointerInfo.event.screenY
    ) {
      pointerInfo.x = pointerInfo.event.screenX;
      pointerInfo.y = pointerInfo.event.screenY;
      console.log(pointerInfo.event.screenX);
      // 마우스 커서 위치를 3D 좌표로 변환
      //this.screenToWorld(this.mouse.event.clientX, this.mouse.event.clientY, this.mouse.position);

      pointerInfo.position.x += center.x;
      pointerInfo.position.y += center.y;

      // 마우스 커서 위치를 UTM으로 표시
      setStateDispatcher({type:StateActionKind.Axis, payload: `${pointerInfo.position.x.toFixed(3)} ${pointerInfo.position.y.toFixed(3)}` });

    }
  }

  const animate = (): void => {
    requestAnimationFrame(animate);

    // 그리기
    draw();

    //this.stats.update();
  }

  const drawMapHD = (data: MapHD[]) => {
    // 정밀지도 그리기
    map = data;
    let leftX = 999999;
    let leftY = 9999999;

    let rightX = 0;
    let rightY = 0;
  
    for (const line of map) {
      for (const point of line.point) {
          // 좌측 상단에 해당하는 좌표 찾기
          if (point.x <= leftX && point.y <= leftY) {
              leftX = point.x;
              leftY = point.y;
          }

          // 우측 하단에 해당하는 좌표 찾기
          if (point.x >= rightX && point.y >= rightY) {
              rightX = point.x;
              rightY = point.y;
          }
      }
    }

    // 중점 좌표
    center = {
        x: (leftX + rightX) / 2,
        y: (leftY + rightY) / 2,
    };

    console.log(center);
    // 차선 생성 및 그리기
    setMapLinePointsDispatcher({type:'CLEAR'});
    for (const line of map) {
      if (-1 !== line.lineID.search('A3_LINK')) {
          // A3_LINK 제외
          continue;
      }
      //dispatcher({type:'CLEAR'});
      let p_ax_pt:number[][] = [];
      
      for (const point of line.point) {
          let ax_pt: number[] = [];
          ax_pt.push(point.x - center.x)
          ax_pt.push(point.y - center.y)
          ax_pt.push(0)
          //console.log(ax_pt);
          //dispatcher({type:'ADD', payload: ax_pt});
          p_ax_pt.push(ax_pt);
      }
      setMapLinePointsDispatcher( {type:'ADD', payload:{ 'lineID' : line.lineID, 'color' : line.color, 'points' : p_ax_pt} })
      //const geometry = new THREE.BufferGeometry().setFromPoints(points);
      //geometry.name = line.lineID;

      //const material = new THREE.LineBasicMaterial({
      //    color: line.color,
      //});
      //const lines = new THREE.Line(geometry, material);

      //this.scene.add(lines);
    }  
  }

  /*
  useThree(({camera}) => {
    camera.rotation.set(deg2rad(30), 0, 0);
  });
  */

  return (
      <div ref={container}
        style={{
          height: "85vh",
          width: "100%",
        }}
      >
        <button onClick={toggleControlStyle}>Change camera controller</button>
        <Canvas
          dpr={window.devicePixelRatio}
          camera={{
            position: new THREE.Vector3(0, 0, 500),
            zoom: 1,
          }}
          onCreated={({ gl }) => {
            gl.setClearColor("#252934");
          }}
        >
            <OrbitControls autoRotate autoRotateSpeed={0} />
            <Suspense fallback={null}>
              <Scene data={mapLinePonits} onStateChange={setStateDispatcher} />
            </Suspense>

          {/*
            <Stats showPanel={0} className={css.stats} {...props} />
            <Sky
              distance={450000} // Camera distance (default=450000)
              sunPosition={[0, 1, 0]} // Sun position normal (default=[0, 1, 0])
            />
        */}
        </Canvas>
        <div className={css.distance}>{state.distance}</div>
        <div className={css.vehicle}>{state.vehicle}</div>
        <div className={css.axis}>{state.axis}</div>
        <div className={css.lineID}>{state.lineID}</div>
      </div>
    );
  }
  
  export default MapAutoDrive