import React, { useState } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import axios from 'axios'
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function PasswordResetView() {
  const { t } = useTranslation();
  const { uid, token } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();

  const [fields, handleFieldChange] = useFormFields({
    new_password1: "",
    new_password2: ""
  });

  function validateForm() {
    return (
      fields.new_password1.length > 0 &&
      fields.new_password2.length > 0 &&
      fields.new_password1 === fields.new_password2
    );
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    axiosInstance.post(`/auth/reset/${uid}/${token}/`, {
                new_password1: fields.new_password1,
                new_password2: fields.new_password2
    }).then(function(response) {
          console.log(response.data);
          history("/");

    }).catch(function (error) {
      if (error && error.response && error.response.status) {
        if (error.response.status === 444) {
          onError(error.response.data.message);
        } else {
          onError(error);
        }
      } else {
        onError(error);
      }
      //console.log(error);
      setIsLoading(false);
    });
  }
  
  return (
  <Stack gap={3}>
    <div className="bg-light border">
      <div className="Login">
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="new_password1" size="lg">
            <Form.Label>new_password1</Form.Label>
              <Form.Control
                type="password"
                onChange={handleFieldChange}
                value={fields.new_password1}
              />
          </Form.Group>
          <Form.Group controlId="new_password2" size="lg">
            <Form.Label>new_password2</Form.Label>
              <Form.Control
                type="password"
                onChange={handleFieldChange}
                value={fields.new_password2}
              />
          </Form.Group>
          <LoaderButton
            block
            size="lg"
            type="submit"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            비밀번호재설정
          </LoaderButton>
        </Form>
      </div>
    </div>
    <div>
        <br /><br /><br />
    </div>
  </Stack>
  );
}