import React from "react";
import { Routes , Route } from 'react-router-dom';
import Home from "./containers/Home";
import Login from "./containers/Login";
import LoginBasic from "./containers/LoginBasic";
import Signup from "./containers/Signup";
import SignupBasic from "./containers/SignupBasic";
import SignupPhone from "./containers/SignupPhone";
import Settings from "./containers/Settings";
import UserDetail from "./containers/UserDetail";
import PasswordResetView from "./containers/PasswordResetView";
import SignupConfirm from "./containers/SignupConfirm";
import NotFound from "./containers/NotFound";
import DrivingMap from "./containers/DrivingMap";
import MapSocket from "./containers/MapSocket";
import NoteNew from "./containers/NoteNew_ckeditor";
import NoteInfo from "./containers/NoteInfo_ckeditor";
import Notes from "./containers/Notes_ckeditor";
import DrivingData from "./containers/DrivingData";
import DownloadCsv from "./containers/DownloadCsv";
import DrivingWebRTC from "containers/DrivingWebRTC";
import DrivingSummary from "containers/DrivingSummary";
import MapLeaflet from "./map/MapLeaflet";
import AdrtReservation from "containers/AdrtReservation";

export default function AppRoutes():JSX.Element {
  
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/loginbasic" element={<LoginBasic />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/signupbasic" element={<SignupBasic />} />
      <Route path="/signupphone" element={<SignupPhone />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/userdetail" element={<UserDetail />} />
      <Route path="/signupconfirm" element={<SignupConfirm />} />
      <Route path="/auth/reset/:uid/:token" element={PasswordResetView}/>
      <Route path="/notes/new" element={<NoteNew />} />
      <Route path="/notes/:id" element={<NoteInfo />} />
      <Route path="/notes" element={<Notes />} />
      <Route path="/drivingcontrol" element={<MapLeaflet />} />
      <Route path="/drivingmap" element={<DrivingMap />} />
      <Route path="/mapsocket" element={<MapSocket />} />
      <Route path="/drivingdata" element={<DrivingData />} />
      <Route path="/downloadcsv" element={<DownloadCsv />} />
      <Route path="/drivingwebrtc" element={<DrivingWebRTC />} />
      <Route path="/drivingsummary" element={<DrivingSummary />} />
      <Route path="/adrtreservation" element={<AdrtReservation />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}