import { useEffect, useRef } from "react";
import L from "leaflet";
import "./AutoDriveVideo.css";
import OvenPlayer from 'ovenplayer';
import { useAppContext } from "../libs/contextLib";

let dom_el_video = null;

function AutoDriveVideo({ map, carInfo, isPopVideoInfo }) {
    const { carAppContext, setCarAppContext } = useAppContext();

    useEffect(() => {
        if (map) {
            if (dom_el_video) {
                L.DomUtil.remove(dom_el_video);
            }

            if (carInfo.control && !isPopVideoInfo) {
                if (dom_el_video) {
                    L.DomUtil.remove(dom_el_video);
                }
                return;
            }

            const info_video = L.control({ position: "topleft" });

            info_video.onAdd = () => {
                info_video._div = L.DomUtil.create("div", "video-wrapper");
                dom_el_video = info_video._div;
                info_video.update(carInfo.control);
                return info_video._div;
            };

            info_video.update = (props) => {
                info_video._div.innerHTML = "<div id='player_id'></div>"
            };

            info_video.addTo(map);
            //console.log(`wss://www.lukesoft.kr:3334/${carAppContext.vehicle_id}/stream`);
            const player = OvenPlayer.create('player_id', {
                sources: [
                    {
                        label: 'label_for_webrtc',
                        // Set the type to 'webrtc'
                        type: 'webrtc',
                        // Set the file to WebRTC Signaling URL with OvenMediaEngine 
                        file: `wss://www.lukesoft.kr:3334/${carAppContext.vehicle_id}/stream`
                    }
                ]
            });

        }
    }, [map, isPopVideoInfo]);

    return null;
}

export default AutoDriveVideo;