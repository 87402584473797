const config = {
    STRIPE_KEY: "pk_test_51I1IrlJtHcLXOeRyHO2j3wVg967RwKnp3sWL3DJwaVxjLjwh4xhPDzCkS7TQICjpDOetHLqHCWcVvTAJ8rSnuMqR00RND5KXUJ",
    MAX_ATTACHMENT_SIZE: 5000000,
    SOCIAL_AUTH_KAKAO_CLIENT_ID: "22ef0723cfbfd6979b634235a5bf6732",
    CLIENT_ID: "a8d6730eab9b57e791ccff76f8167524",
    REDIRECT_URI: "https://autodrive.sonnet.ai/accounts/kakao/callback/react",
    REDIRECT_URI_JTNET: "http://autodrive.sonnet.ai/accounts/jtnet/callback",
    REDIRECT_URI_JTNET_GENERAL: "http://autodrive.sonnet.ai/accounts/jtnet/callback/general",
    HOST_URL: "https://autodrive.sonnet.ai",
    SOCKET_URL: "wss://autodrive.sonnet.ai:9002",
    MAP_SERVER: "https://autodrive.sonnet.ai:9002/map",
    UDP_SERVER: "https://autodrive.sonnet.ai:9002/udp",
    UDPIMAGE_SERVER: "https://autodrive.sonnet.ai:9002/udpimage",
    DJANGO_URL: "https://autodrive.sonnet.ai:8082",
    s3: {
      REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
      BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME",
    },
    apiGateway: {
      REGION: "YOUR_API_GATEWAY_REGION",
      URL: "YOUR_API_GATEWAY_URL",
    },
    cognito: {
      REGION: "YOUR_COGNITO_REGION",
      USER_POOL_ID: "YOUR_COGNITO_USER_POOL_ID",
      APP_CLIENT_ID: "YOUR_COGNITO_APP_CLIENT_ID",
      IDENTITY_POOL_ID: "YOUR_IDENTITY_POOL_ID",
    },
  };
  
  export default config;