import React from "react";
import { Container, Nav, Navbar, Stack } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from 'react-i18next';
import "./SubNavigation.css";

function SubNavigationSettings() {
  const { t } = useTranslation();
  
  return (

        <div className="bg-light border my-2">
          <Nav fill variant="pills">
            <LinkContainer to="/settings" className="nav-link me-5" style={{ cursor: 'pointer' }}>
                <strong>{t('SETTINGS')}</strong>
            </LinkContainer>
            <LinkContainer to="/notes" className="nav-link mx-5" style={{ cursor: 'pointer' }}>
                <strong>공지사항관리</strong>
              </LinkContainer>
            <LinkContainer to="/drivingwebrtc" className="nav-link mx-5" style={{ cursor: 'pointer' }}>
                <strong>WebRTC Test</strong>
            </LinkContainer>
            <LinkContainer to="/mapsocket" className="nav-link mx-5" style={{ cursor: 'pointer' }}>
                <strong>웹소켓테스트</strong>
            </LinkContainer>
          </Nav>
        </div>

  );
}

export default SubNavigationSettings;