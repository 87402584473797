export type User = {
    email: string;
    is_active: boolean;
    is_staff: boolean;
    nickname: string;
    phone_number: string;
    pk: number;
    status: string;
  }

export const defaultUser = {
    email: '',
    is_active: false,
    is_staff: false,
    nickname: '',
    phone_number: '',
    pk: 0,
    status: '',
}
  
export type Route = {
    id: number;
    routename: string;
    routegubun: string;
    ref_lat: string;
    ref_lng: string;
    defalt_zoom: string;
    crt_date: string;
    crt_emp: User
  }

export const defaultRoute = {
    id:1,
    routename: '대구 테크노폴리스 구간',
    routegubun: 'A',
    ref_lat: '35.69386',
    ref_lng: '128.45977',
    defalt_zoom: '14',
    crt_date:'',
    crt_emp: defaultUser,
}
  
export type Station = {
    id: number;
    stationname: string;
    site_lat: string;
    site_lng: string;
    crt_date: string;
    crt_emp: User
  }

export const defaultStation = {
    id: 0,
    stationname: '',
    site_lat: '',
    site_lng: '',
    crt_date: '',
    crt_emp: defaultUser,
}

export type Car = {
  id: number;
  carname: string;
  car_status: string;
  route: Route;
  station: Station;
  car_direction: string;
  site_lat: string;
  site_lng: string;
  vehicle_id: string;
  crt_date: string;
  crt_emp: User
}

export const defaultCar = {
  id: 0,
  carname: '',
  car_status: '',
  route: defaultRoute,
  station: defaultStation,
  car_direction: '',
  site_lat: '',
  site_lng: '',
  vehicle_id: '',
  crt_date: '',
  crt_emp: defaultUser,
}
  
export type RouteMap = {
    id: number;
    sequence: string;
    leadtime: number;
    charge: number;
    crt_date: string;
    crt_emp: User;
    route: Route;
    station: Station;
  }

export const defaultRouteMap = {
    id: 0,
    sequence: '',
    leadtime: 0,
    charge: 0,
    crt_date: '',
    crt_emp: defaultUser,
    route: defaultRoute,
    station: defaultStation,
}

export interface IAppContext {
    isAuthenticated: boolean;
    userHasAuthenticated: (isAuthenticated:boolean) => void;
    user: User;
    setUser: (user:User) => void;
    routeAppContext:Route;
    setRouteAppContext: (routeAppContext:Route) => void;
    strStationAppContext:Station;
    setStrStationAppContext: (strStationAppContext:Station) => void;
    currentLocation:string; 
    setCurrentLocation: (currentLocation:string) => void;
    routeMap:RouteMap;
    setRouteMap: (routeMap:RouteMap) => void;
    carAppContext:Car;
    setCarAppContext: (carAppContext:Car) => void;
  }

export const defaultIAppContext = {
    isAuthenticated: false,
    userHasAuthenticated: () => null,
    user: defaultUser,
    setUser: () => {},
    routeAppContext: defaultRoute,
    setRouteAppContext: () => {},
    strStationAppContext: defaultStation,
    setStrStationAppContext: () => {},
    currentLocation: '',
    setCurrentLocation: () => {},
    routeMap: defaultRouteMap,
    setRouteMap: () => {},
    carAppContext: defaultCar,
    setCarAppContext: () => {},
}