import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Stack } from 'react-bootstrap';
import OvenPlayer from 'ovenplayer';
import axiosInstance from "../axiosApi";
import { onError } from "../libs/errorLib";
import moment from "moment";
import 'moment/locale/ko';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useTranslation } from 'react-i18next';
import ToggleSwitch from "../components/ToggleSwitch";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { IconContext } from "react-icons";
import TableDateRange from "./TableDateRange";
import * as dateFns from "date-fns";
import LoadingSpinner from "../components/LoadingSpinner";
import { useAppContext } from "../libs/contextLib";
import "./DrivingWebRTC.css";

function DrivingWebRTC() {

    useEffect(() => {
        // Initialize OvenPlayer
        const player = OvenPlayer.create('player_id', {
            sources: [
                {
                    label: 'label_for_webrtc',
                    // Set the type to 'webrtc'
                    type: 'webrtc',
                    // Set the file to WebRTC Signaling URL with OvenMediaEngine 
                    file: 'wss://www.lukesoft.kr:3334/app/stream'
                }
            ]
        });
    }, []);

    return (
        <Stack gap={3}>
            <div className="bg-light border">
                <div className="player-wrapper">
                    <div id="player_id"></div>
                </div>
            </div>
            <div>
                <br /><br /><br />
            </div>
        </Stack>
    );
}

export default DrivingWebRTC;