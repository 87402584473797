import React, { useState, useEffect } from "react";
import "./Home.module.css";
import { Accordion, Stack, Button, Modal } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../libs/contextLib";
import axiosInstance from "../axiosApi";
import axios from 'axios'
import config from "../config";
import { onError } from "../libs/errorLib";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Kona_front from '../images/kona2-front-black.png';
import { useTranslation } from 'react-i18next';
import MapLeaflet from "../map/MapLeaflet";
import Announcement from "components/Announcement";
import { defaultStation } from "../types/app";

function NotePopupModal(props: any) {
  const { t } = useTranslation();

  const Viewer = (content: any) => (
    <div
      className="ck-content"
      dangerouslySetInnerHTML={{ __html: content }}
    ></div>
  );
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('ANNOUNCEMENT')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{props.subject}</h5>
        <p>
          <Viewer content={props.content} />
        </p>
        <span><i className="fas fa-angle-double-right"></i> {t('ATTACHMENTS')}</span>
        {props.filemodels && (
          props.filemodels.map((data: any, index: any) => {
            return (
              <>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${config.DJANGO_URL}` + data.file_save_name}
                  >
                    {index + 1}{"   "}{data.id}{data.file_origin_name}
                  </a>
                </p>
              </>);
          })
        )}
      </Modal.Body>
      <Modal.Footer>
        {t('DATE_ISSUE')} : {props.crt_date}
      </Modal.Footer>
    </Modal>
  );
}

export default function Home() {
  const [accessToken, setAccessToken] = useState('');
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();
  const [route, setRoute] = useState([]);
  const { setRouteAppContext } = useAppContext();
  const { setStrStationAppContext } = useAppContext();
  const [modalShow, setModalShow] = React.useState(false);
  const [crt_date, setCrt_date] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [filemodels, setFilemodels] = useState([]);
  const { t } = useTranslation();
  const { currentLocation, setCurrentLocation } = useAppContext();
  setCurrentLocation('/');

  useEffect(() => {
    async function onLoad() {
      //console.log(localStorage.getItem('access_token'));
      axios.get(`${config.DJANGO_URL}/robotaxi/route/`).then(function (response) {
        setRoute(response.data);
        setRouteAppContext(response.data[0]);
        setStrStationAppContext(defaultStation);
        //1. check Note Popup info
        if (isAuthenticated) {
          axiosInstance.get('/robotaxi/notepopup/').then(function (response) {
            if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
            //console.log(response.data);
            if (response.data.length > 0) {
              setCrt_date(response.data[0].crt_date);
              setSubject(response.data[0].subject);
              setContent(response.data[0].content);
              setFilemodels(response.data[0].filemodels);
              showModal();

              axiosInstance.post('/robotaxi/notepopupcheck/', {
                note_id: response.data[0].id
              }).then(function (response) {
                //console.log(response.data);
              }).catch(function (error) {
                console.log(error);
              });
            }
          }).catch(function (error) {
            console.log(error);
          });
        }
      }).catch(function (error) {
        //onError(error);
      });
    }

    onLoad();
  }, []);

  function showModal() {
    setModalShow(true);
  }

  function hideModal() {
    setModalShow(false);
    window.location.reload();

    history("/", { replace: true });
  }

  function renderHome() {
    return (
      <Stack gap={3}>
        <div className="card-body">
          <div className="row">
            <div className="col-xs-2">
            </div>
            <div className="col-xs-8">
              <p className="text-center">
                <h3>{t('WELCOME_001')}</h3>
              </p>
            </div>
            <div className="col-xs-2">
            </div>
          </div>

          <div className="row">
            <div className="col-4">
            </div>
            <div className="col-4">
              <div className="center-block">
                <img src={Kona_front} className="img-fluid" alt="..."></img>
              </div>
            </div>
            <div className="col-4">
            </div>
          </div>
        </div>
        <Announcement />
        <div>
          <NotePopupModal
              show={modalShow}
              onHide={hideModal}
              crt_date={crt_date}
              subject={subject}
              content={content}
              filemodels={filemodels}
          />
        </div>
        <div>
          <br /><br /><br />
        </div>
      </Stack>
    );
  }

  function renderIntro() {
    return (
      <MapLeaflet />
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderHome() : renderIntro()}
    </div>
  );
}