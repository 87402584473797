import React, { useState, useRef, useEffect } from 'react'
// Import useMapEvents:
import {
  useMapEvents,
  useMapEvent,
  TileLayer,
  LayersControl,
  LayerGroup,
  GeoJSON,
  Marker,
  Popup,
  Tooltip,
  VideoOverlay
} from 'react-leaflet'
// Import L from leaflet to create LatLng objects:
import L from 'leaflet'
// Import Material UI Components for styling:
import { Typography, Divider } from '@material-ui/core'
import YG from '../data/Yugaeup.json'
import ByLines from '../data/Techno_A2_LINK.json'
import TechpolMap from '../data/techpol_map.json'
import GangneungMap from '../data/gangneung_map.json'
import SuseongMap from '../data/suseong_map.json'
import DongseongroCourse3Map from '../data/dongseongro_course3_map'
import MT from '../data/Montana.json'
import ND from '../data/NorthDakota.json'
import SD from '../data/SouthDakota.json'
import { renderToStaticMarkup } from 'react-dom/server';
import { IconContext } from "react-icons";
import { FaCar } from "react-icons/fa";
import AutoDriveLegend from './AutoDriveLegend';
import AutoDriveVideo from './AutoDriveVideo'
import { useAppContext } from "../libs/contextLib";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

const customIconCar = L.divIcon({
  //html: '<i className="fa fa-car fa-2x" />',
  //iconSize: [30, 42],
  //iconAnchor: [15, 42],
  className: 'custom-icon',
  html: "<div style='background-color:#4838cc;'></div><i class='fa fa-car fa-2x'>",
})

const customIconStation = L.divIcon({
  className: 'custom-div-icon',
  html: "<div style='background-color:#4838cc;' class='marker-pin'></div><i class='fas fa-stop-circle awesome'>",
  iconSize: [30, 42],
  iconAnchor: [15, 42]
});

const Layers = (props) => {
  const markerRef = useRef(null);
  // Set all the border data to state array:
  const [borderData, setBorderData] = useState([YG])
  const [linesData, setLinesData] = useState([ByLines])
  const { routeAppContext, setRouteAppContext } = useAppContext();

  // Function to provide each state's latLng:
  const getMarkerPosition = (state_name) => {
    switch (state_name) {
      case 'Montana':
        // Use L.latLng to create latLng object.
        // { "lat": LATITUDE, "lng": LONGITUDE }
        return L.latLng(46.8797, -110.3626)
      case 'Wyoming':
        return L.latLng(43.0760, -107.2903)
      case 'North Dakota':
        return L.latLng(47.5515, -101.0020)
      case 'South Dakota':
        return L.latLng(43.9695, -99.9018)
      case '유가읍':
        return L.latLng(35.69386, 128.45977)
      case 'Dgist':
        return L.latLng(35.7042, 128.458622)
      case 'Robocar':
        return L.latLng(35.69703, 128.45775)
      default: return
    }
  }

  const stateStyle = {
    color: "black",
    weight: 0.5
  }

  const onEachId = (id, layer) => {
    //console.log(id);
  }

  const position = [35.69703, 128.45775];
  const [markerPosition, setMarkerPosition] = useState(L.latLng(position))
  const eventHandlers = {
    dragstart: () => {
      const marker = markerRef.current;
      marker.setOpacity(0.6);
    },
    dragend: () => {
      const marker = markerRef.current;
      marker.setOpacity(1);
      setMarkerPosition(marker.getLatLng());
    }
  }

  return (
    <>
      <AutoDriveLegend map={props.map} carInfo={props.carInfoLast} />
      <AutoDriveVideo map={props.map} carInfo={props.carInfoLast} isPopVideoInfo={props.isPopVideoInfo} />
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="Basic Map">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Esri Satellite Map">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
            />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Topo Map">
          <TileLayer
            attribution='Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
            url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.Overlay checked name="TechnoPolice">
          <GeoJSON style={{color: "red", weight: 2}} data={TechpolMap} />
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name="Gangneung">
          <GeoJSON style={{color: "red", weight: 2}} data={GangneungMap} />
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name="Suseong">
          <GeoJSON style={{color: "red", weight: 2}} data={SuseongMap} />
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name="Dongseongro Course3">
          <GeoJSON style={{color: "red", weight: 2}} data={DongseongroCourse3Map} />
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name="Stations">
          <LayerGroup>

            {props.routeMap.map((data) => {
              return (
                  <Marker 
                    key={data.station.id}
                    id={data.station.id}
                    position={L.latLng(data.station.site_lat, data.station.site_lng)} 
                    icon={customIconStation}
                  >
                    <Tooltip permanent>
                      <span>{data.station.stationname}</span>
                    </Tooltip>
                    {/*
                    <Popup>
                      <Typography variant='subtitle2'>
                        {data.station.stationname}
                      </Typography>
                      <Divider />
                      <Typography variant='body2' style={{ margin: 3 }}>
                        Lat: {data.station.site_lat}
                      </Typography>
                      <Typography variant='body2' style={{ margin: 3 }}>
                        Lng: {data.station.site_lng}
                      </Typography>
                    </Popup>
                    */}
                  </Marker>
              );
            })}
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name="Car">
          <LayerGroup>
            {props.carInfo.map((data) => {
              if (data.route_id == routeAppContext.id) {
                //console.log(data); 
                //L.latLng(data.latitude, data.longitude)
                //setMarkerPosition(marker.getLatLng());
                //setMarkerPosition(L.latLng(data.latitude, data.longitude));
                return (
                  <Marker 
                    key={data.vehicle_id}
                    id={data.vehicle_id}
                    position={L.latLng(data.latitude, data.longitude)} 
                    icon={customIconCar} 
                    draggable={true}
                    ref={markerRef}
                    eventHandlers={eventHandlers}
                    >
                      <Tooltip permanent>
                        <span>{data.vehicle_id}</span>
                        <span> Lat: {data.latitude}</span>
                        <span> Lng: {data.longitude}</span>
                        <span> Speed: {data.speed}</span>
                      </Tooltip>
                  </Marker>
                );
              }
              
            })}
          </LayerGroup>
        </LayersControl.Overlay>
      </LayersControl>
    </>
  )
}

export default Layers