import React, { useState, useCallback, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Nav, Navbar, Stack, Form, Row, Col, Button } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import carInfoData from "../data/carinfo_kona_003.json"
import config from "../config";

const MapSocket = () => {
  //Public API that will echo messages sent to it back to the client
  const [socketUrl, setSocketUrl] = useState(`${config.SOCKET_URL}/ws`);
  const [carInfo, setCarInfo] = useState("");
  const [messageHistory, setMessageHistory] = useState([]);

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket
  } = useWebSocket(socketUrl, {
    onOpen: () => console.log('opened'),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    if (lastMessage !== null) {
      if (JSON.parse(lastMessage.data).type == 'mapHD') {
        //setMessageHistory((prev) => prev.concat(lastMessage));
      } else {
        setMessageHistory((prev) => prev.concat(lastMessage.data));
      }
      
    }
  }, [lastMessage, setMessageHistory]);

  const handleClickChangeSocketUrl = useCallback(
    () => setSocketUrl('wss://demos.kaazing.com/echo'),
    []
  );

  const handleClickSendMessage = useCallback(() => {
    
    carInfoData.data.speed = 80;
    //carInfoData.data.latitude = "35.69703";
    //carInfoData.data.longitude = "128.45775";
    //console.log(carInfoData);
    sendJsonMessage(carInfoData)
  }, []);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  return (
    <Stack gap={3}>
      <div className="bg-light border">
          <Nav>
                <LinkContainer to="/drivingcontrol">
                    <Nav.Link>자율주행관제</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/drivingmap">
                    <Nav.Link>자율주행맵</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/mapsocket">
                    <Nav.Link>웹소켓테스트</Nav.Link>
                </LinkContainer>
          </Nav>
      </div>
      <div>
        <button onClick={handleClickChangeSocketUrl}>
          Click Me to change Socket Url
        </button>
        <button
          onClick={handleClickSendMessage}
          disabled={readyState !== ReadyState.OPEN}
        >
          Click Me to send 'Hello'
        </button>
        <span>The WebSocket is currently {connectionStatus}</span>
        {lastMessage ? <span>Last message: {JSON.parse(lastMessage.data).type}</span> : null}
        <ul>
          {messageHistory.map((message, idx) => (
            <span key={idx}>{message ? message : null}</span>
          ))}
        </ul>
      </div>
      <div>
          <br /><br /><br />
      </div>
  </Stack>
  );
};

export default MapSocket;