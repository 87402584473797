import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import axiosInstance from "./axiosApi";

export interface Payload {
  notification: any,
  token: string,
  data: any
}

export const defaultPayload = {
  notification: '',
  token: '',
  data: ''
}

const firebaseConfig = {
  apiKey: "AIzaSyAzBfeVYw3mS35_amllGYQyIqlgqm60KjI",
  authDomain: "rational-iris-289208.firebaseapp.com",
  projectId: "rational-iris-289208",
  storageBucket: "rational-iris-289208.appspot.com",
  messagingSenderId: "56561218933",
  appId: "1:56561218933:web:9e31d8776c33ffed76cf3c",
  measurementId: "G-V3Z8RQPT3N"
};

firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();

export const getToken = (setTokenFound:any) => {
  return messaging.getToken({vapidKey: 'BC5hK5xkBiJvdpTcl7R1VmnfcdZbtqqEx8qJiGt27VbbeVb2yR0UoSEteuwZbeGasUrWfRwRbYCVZEXTOLXfYug'}).then((currentToken) => {
    if (currentToken) {
      //console.log('current token for client: ', currentToken);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      //console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    //console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload:Payload) => {
      resolve(payload);
    });
});

export const setToken = (app_name:any, user_email:any) => {
  return messaging.getToken({vapidKey: 'BC5hK5xkBiJvdpTcl7R1VmnfcdZbtqqEx8qJiGt27VbbeVb2yR0UoSEteuwZbeGasUrWfRwRbYCVZEXTOLXfYug'}).then((currentToken) => {
    if (currentToken) {
       axiosInstance.get('/robotaxi/messagetoken/',{
                params: {
                    app_name: app_name,
                    email: user_email
                }
       }).then(function(response) {
            axiosInstance.put(`/robotaxi/messagetoken/${response.data[0].id}/`,
                {
                  client_token: currentToken
                }).then(function(response) {
              return;
            }).catch(function (error) {
              //console.log(error);
            }); 
       }).catch(function (error) {
          axiosInstance.post('/robotaxi/messagetoken/',{
                    app_name: app_name,
                    client_token: currentToken,
                    email: user_email
          }).then(function(response) {
            //console.log(response.data);
          }).catch(function (error) {
              //console.log(error);
          });
       });
    } else {
      console.log('No registration token available. Request permission to generate one.');
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}