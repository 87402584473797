import React from 'react';
import { useTranslation } from 'react-i18next';

const DropdownSelector = props => {
  const { t } = useTranslation();
  
  return (
    <div className="form">
        <label>{props.labelName}</label>{"   "}
        <select name="{props.labelName}" onChange={props.onChange} value={props.defaultValue}>
            <option>{props.labelName} {t('SELECT')}</option>
            {
                props.options.map(option => (
                    <option key={option.id} value={option.id}>{option.name}</option>
                ))
            }
          )
        </select>
    </div>
  )
}
 
export default DropdownSelector;