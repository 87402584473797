import React, { useMemo, useState, useEffect } from "react";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import { useTranslation } from 'react-i18next';
import {
    MRT_EditActionButtons,
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
//Import Material React Table Translations
import { MRT_Localization_KO } from 'material-react-table/locales/ko';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Checkbox,
    Tooltip,
    FormControlLabel,
    Typography,
    InputLabel,
    useTheme
} from '@mui/material';

import {
    QueryClient,
    QueryClientProvider,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import TableDatePicker from "./TableDatePicker";
import * as dateFns from "date-fns";

const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
});

function DrivingSummaryMui() {
    const { t } = useTranslation();
    const [validationErrors, setValidationErrors] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [rowSelection, setRowSelection] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { currentLocation, setCurrentLocation } = useAppContext();
    setCurrentLocation('/drivingsummary');

    //call READ hook
    const {
        data: fetcheddrivingsummary = [],
        isError: isLoadingdrivingsummaryError,
        isFetching: isFetchingdrivingsummary,
        isLoading: isLoadingdrivingsummary,
        refetch
    } = useGetDrivingSummary();

    const columns = useMemo(
        () => [
            {
                accessorKey: 'crt_date', //simple recommended way to define a column
                header: '운행일자',//optional custom props
                size: 150,
            },
            {
                accessorKey: 'route_id', //simple recommended way to define a column
                header: '노선ID',//optional custom props
                size: 50,
            },
            {
                accessorKey: 'route_name', //simple recommended way to define a column
                header: '노선명',//optional custom props
                size: 200,
            },
            {
                accessorKey: 'vehicle_id', //simple recommended way to define a column
                header: '차량ID',//optional custom props
                size: 50,
            },
            {
                accessorKey: 'rec_cnt', //simple recommended way to define a column
                header: '차량정보(건수)',//optional custom props
                size: 100,
            },
            {
                accessorKey: 'min_date', //simple recommended way to define a column
                header: '시작일시',//optional custom props
                size: 150,
            },
            {
                accessorKey: 'max_date', //simple recommended way to define a column
                header: '종료일시',//optional custom props
                size: 150,
            },
            {
                accessorKey: 'min_speed', //simple recommended way to define a column
                header: '최저속도',//optional custom props
            },
            {
                accessorKey: 'max_speed', //simple recommended way to define a column
                header: '최고속도',//optional custom props
            },
            {
                accessorKey: 'min_driven', //simple recommended way to define a column
                header: '이동거리(시작)',//optional custom props
            },
            {
                accessorKey: 'max_driven', //simple recommended way to define a column
                header: '이동거리(종료)',//optional custom props
            },
        ],
        [validationErrors],
    );

    const handleExportData = () => {
        const csv = generateCsv(csvConfig)(fetcheddrivingsummary);
        download(csvConfig)(csv);
    };

    const table = useMaterialReactTable({
        columns,
        data: fetcheddrivingsummary,
        initialState: {
            density: 'compact',
            pagination: { pageSize: 10, pageIndex: 0 }
        },
        muiTableHeadCellProps: {
            //simple styling with the `sx` prop, works just like a style prop in this example
            sx: {
                fontWeight: 'bold',
                fontSize: '14px',
                backgroundColor: '#D3EFBD',
            },
        },
        enableRowSelection: true,
        enableMultiRowSelection: false,
        onRowSelectionChange: setRowSelection,
        state: { rowSelection },
        columnFilterDisplayMode: 'popover',
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                }}
            >
                <Button
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    onClick={handleExportData}
                    startIcon={<FileDownloadIcon />}
                >
                    Export All Data
                </Button>
            </Box>
        ),
    });

    useEffect(() => {
        if(rowSelection) {
            //console.info(table.getSelectedRowModel().rows[0].original);
            if (table.getSelectedRowModel().rows.length > 0) {
                const confirmed = window.confirm("상세정보를 다운로드 하시겠습니까?");
                if (!confirmed) {
                    return;
                }
                //console.info(table.getSelectedRowModel().rows[0].original);
                onDownloadDetail(dateFns.format(startDate, "yyyy-MM-dd"),
                    table.getSelectedRowModel().rows[0].original.route_id, 
                    table.getSelectedRowModel().rows[0].original.vehicle_id);
            }
        }
    }, [rowSelection]);

    async function onDownloadDetail(s_date, route_id, vehicle_id) {
        setIsLoading(true);
        const response = await axiosInstance.get('/robotaxi/drivesummarydetail/', {
            params: { 
                s_date: s_date,
                route_id: route_id,
                vehicle_id: vehicle_id
            }
        });
        //console.log(response.data);
        const csv = generateCsv(csvConfig)(JSON.parse(response.data[0]));
        download(csvConfig)(csv);
    }
    
    //READ hook (get users from api)
    function useGetDrivingSummary() {
        return useQuery({
            queryKey: ['drivingsummary'],
            queryFn: async () => {
                //console.log(dateFns.format(startDate, "yyyy-MM-dd"));
                const response = await axiosInstance.get('/robotaxi/drivesummary/', {
                    params: {
                        s_date: dateFns.format(startDate, "yyyy-MM-dd")
                    }
                });
                //console.log(response.data);
                return JSON.parse(response.data[0]);
            },
        });
    }

    async function onChangeStartDate(event) {
        setStartDate(startDate => event);
    }

    useEffect(() => {
        refetch();
    }, [startDate]);

    return (
        <Box m="10px">
            <h6>운행내역을 클릭하면 상세데이터를 다운로드할수 있습니다</h6>
            <Box sx={{ display: 'flex', marginTop: 3 }}>
                <InputLabel>기준일자 : </InputLabel>

                <TableDatePicker
                    wrapperClassName="datepicker"
                    selected={startDate}
                    onChange={onChangeStartDate}
                />
            </Box>
            <Box
                mt="10px"
            >
                <MaterialReactTable table={table} />
            </Box>

        </Box>);
}

export default DrivingSummaryMui;