import { MapContainer, ZoomControl, useMap, useMapEvent } from 'react-leaflet'
import Layers from './Layers'
import "leaflet/dist/leaflet.css"
import "./Map.css"
import React, { useState, useCallback, useEffect, useReducer, ButtonGroup } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Container, Table , Row, Col, ToggleButton, Stack, Button, Modal, Badge } from 'react-bootstrap';
import styled from 'styled-components';
import carInfoData_kona_001 from "../data/carinfo_kona_001.json"
import carInfoData_kona_002 from "../data/carinfo_kona_002.json"
import carInfoData_kona_003 from "../data/carinfo_kona_003.json"
import carInfoData_ka4_001 from "../data/carinfo_ka4_001.json"
import carInfoData_ka4_002 from "../data/carinfo_ka4_002.json"
import carInfoData_ka4_003 from "../data/carinfo_ka4_003.json"
import carInfoData_ka4_004 from "../data/carinfo_ka4_004.json"
import { useAppContext } from "../libs/contextLib";
import axiosInstance from "../axiosApi";
import axios from 'axios'
import config from "../config";
import { onError } from "../libs/errorLib";
import { useTranslation } from 'react-i18next';
import DropdownSelector from "../components/DropdownSelector";
import LoadingSpinner from "../components/LoadingSpinner";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import 'moment/locale/ko';
import ToggleSwitch from "../components/ToggleSwitch";

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #E2E4F6;
    borderRadius: '50px';
    /* 다른 스타일도 추가 가능 */
  }
`;

const StyledTable = styled(Table)`
  /* 여기에 원하는 스타일을 추가하세요 */
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  th, td {
    border-bottom: 1px solid #444444;
    padding: 5px;
    text-align: center;
  }
`;

function MyVerticallyCenteredModal(props) {
  const reservations = [
    { id: 1, booker: '2578', route: 'A노선경유지-3', seatnumber: '1', due: 'Now' },
    { id: 2, booker: '2645', route: 'A노선종착지', seatnumber: '2', due: '2024-05-05 15:45' },
    // 다른 예약 정보 추가
  ];

  return (
    <StyledModal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          예약정보
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>정류장 : {props.stationName}</h5>
        <StyledTable striped bordered hover variant="dark">
          <thead>
            <tr>
              <th>예약자</th>
              <th>좌석번호</th>
              <th>경로</th>
              <th>탑승예정</th>
            </tr>
          </thead>
          <tbody>
             {props.routeStops.result == "NOK" ?
                reservations.map((data) => (
                <tr key={data.id}>
                  <td>{data.booker}</td>
                  <td>{data.seatnumber}</td>
                  <td>{data.route}</td>
                  <td>{data.due}</td>
                </tr>
              ))
              :
              <tr key="1">
                <td colSpan={4}>{props.routeStops.message}</td>
              </tr>
            }
          </tbody>
        </StyledTable>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </StyledModal>
  );
}


const carInfoReducer = (prevState, action) => {
  let array;
  switch (action.type) {
    case 'ADD':
      array = [...prevState];
      const indexOfObject = array.findIndex(function (data) { return data.vehicle_id === action.payload.vehicle_id });
      if (indexOfObject !== -1) {
        array.splice(indexOfObject, 1);
        array.push(action.payload);
      } else {
        array.push(action.payload);
      }
      //console.log(indexOfObject);
      //console.log(array.find( function(data) { return data.vehicle_id === action.payload.vehicle_id } ));
      //console.log(action.payload.vehicle_id);
      
      return array;
    case 'REMOVE':
      array = [...prevState];
      array.pop();
      return array;
    case 'CLEAR':
      return prevState = [];
    default:
      break;
  }
};

const MapLeaflet = () => {
  const { t } = useTranslation();
  const [map, setMap] = useState(null);
  //Public API that will echo messages sent to it back to the client
  const [socketUrl, setSocketUrl] = useState(`${config.SOCKET_URL}/ws`);
  const [carInfo, dispatcher] = useReducer(carInfoReducer, []);
  const [carInfoLast, setCarInfoLast] = useState(carInfoData_kona_003);
  const [onVehicleId, setOnVehicleId] = useState("");
  const { routeAppContext, setRouteAppContext } = useAppContext();
  const { carAppContext, setCarAppContext } = useAppContext();
  const { isAuthenticated } = useAppContext();
  const [carOptions, setCarOptions] = useState([]);
  const [routeMap, setRouteMap] = useState([]);
  const [carList, setCarList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [routeOptions, setRouteOptions] = useState([]);
  const history = useNavigate();
  const [position, setPosition] = useState({
    lat: 35.69386,
    lng: 128.45977,
  });
  const [flyToPosition, setFlyToPosition] = useState({
    lat: 35.69386,
    lng: 128.45977,
  });
  const [isSwitchFlyToMap, setIsSwitchFlyToMap] = useState(false);
  const [isPopStationInfo, setIsPopStationInfo] = useState(false);
  const [isPopVideoInfo, setIsPopVideoInfo] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [routeStops, setRouteStops] = useState("");
  const [stationName, setStationName] = useState("");
  const [inFlag, setInFlag] = useState(false);
  const [getFlag, setGetFlag] = useState(false);
  const [inStation, setInStation] = useState(0);
  const { currentLocation, setCurrentLocation } = useAppContext();
  const [routeId, setRouteId] = useState("1");
  setCurrentLocation('/drivingcontrol');

  const onSwitchFlyToMap = () => {
    setIsSwitchFlyToMap(!isSwitchFlyToMap);
    //console.log(isSwitchOnService);
  };

  const onSwitchPopStationInfo = () => {
    setIsPopStationInfo(!isPopStationInfo);
    //console.log(isSwitchOnService);
  };

  const onSwitchPopVideoInfo = () => {
    setIsPopVideoInfo(!isPopVideoInfo);
    //sendJsonMessage(carInfoLast);
    //console.log(isSwitchOnService);
  };

  const addCarInfoHandler = (carInfo) => {
    dispatcher({ type: 'ADD', payload: carInfo });
  }

  const clearCarInfoHandler = (carInfo) => {
    dispatcher({ type: 'CLEAR', payload: carInfo });
  }

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket
  } = useWebSocket(socketUrl, {
    onOpen: () => console.log('opened'),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    if (lastMessage !== null) {
      if (JSON.parse(lastMessage.data).type == 'mapHD') {
        //setMessageHistory((prev) => prev.concat(lastMessage));
      } else {
        //console.log(lastMessage.data);
        //console.log(JSON.parse(lastMessage.data));
        var jsonLastMessage = JSON.parse(lastMessage.data)
        //clearCarInfoHandler(JSON.parse(lastMessage.data).data);
        //console.log(routeAppContext);
        //console.log(carAppContext);
        if ((isAuthenticated) && (routeAppContext.id == jsonLastMessage.data.route_id)) {
          addCarInfoHandler(jsonLastMessage.data);
          setCarInfoLast(jsonLastMessage);
          setOnVehicleId(jsonLastMessage.data.vehicle_id)
          //console.log(jsonData.longitude);
          //console.log(lastMessage.data);
          if (jsonLastMessage.data.vehicle_id == carAppContext.vehicle_id) {
            if(!jsonLastMessage.control) {
              var controlData = new Object();
              controlData.route_id = jsonLastMessage.data.route_id;
              controlData.vehicle_id = jsonLastMessage.data.vehicle_id;
              controlData.ome_webrtc = isPopVideoInfo ? "PLAY" : "STOP";
              jsonLastMessage.control = controlData
            } else {
              jsonLastMessage.control.ome_webrtc = isPopVideoInfo ? "PLAY" : "STOP";
            }
            setFlyToPosition({ lat: Number(jsonLastMessage.data.latitude), lng: Number(jsonLastMessage.data.longitude) })
          }
        }
        
        //setMessageHistory((prev) => prev.concat(lastMessage.data));
      }

    }
  }, [lastMessage]);

  useEffect(() => {
    if (map && position.lat !== 0) {
      //console.log(map.getCenter());
      map.flyTo(position, map._zoom)
    }
  }, [position]);

  useEffect(() => {
    setRouteId(prev => routeAppContext.id);
    setIsLoading(true);
    onLoad();
  }, []);

  async function onLoad() {
    try {
      setIsLoading(true);
      setCarOptions([]);
      setCarList([]);
      //console.log(localStorage.getItem('access_token'));
      axios.get(`${config.DJANGO_URL}/robotaxi/route/`).then(function(response) {
        //console.log(response.data);
        setRouteOptions(response.data.map( data => ( { name: data.routename, id: data.id, route:data} ) ));

        setPosition({ lat: Number(routeAppContext.ref_lat), lng: Number(routeAppContext.ref_lng) });
        setFlyToPosition({ lat: Number(routeAppContext.ref_lat), lng: Number(routeAppContext.ref_lng) });
        axios.get(`${config.DJANGO_URL}/robotaxi/routemap/`, {
          params: { id: routeAppContext.id }
        }).then(function (response) {
          setRouteMap(response.data);
        }).catch(function (error) {
          console.log(error);
        });
        axios.get(`${config.DJANGO_URL}/robotaxi/robocar/`, {
          params: { route_id: routeAppContext.id }
        }).then(function (response) {
          setCarList(response.data);
          setCarAppContext(response.data[0]);
          response.data.map((data) => {
            setCarOptions(carOptions => [...carOptions, { name: data.carname, id: data.id, car: data }]);
          });
        }).catch(function (error) {
          console.log(error);
        });

        //initialize carinfo
        addCarInfoHandler(carInfoData_ka4_001.data);
        addCarInfoHandler(carInfoData_ka4_002.data);
        addCarInfoHandler(carInfoData_ka4_003.data);
        addCarInfoHandler(carInfoData_ka4_004.data);
        addCarInfoHandler(carInfoData_kona_001.data);
        addCarInfoHandler(carInfoData_kona_002.data);
        addCarInfoHandler(carInfoData_kona_003.data);

        setIsLoading(false);
      }).catch(function (error) {
        setIsLoading(false);
        onError(error);
      }); 
    } catch (error) {
      onError(error);
    }
  }

  const handleClickChangeSocketUrl = useCallback(
    () => setSocketUrl('wss://demos.kaazing.com/echo'),
    []
  );

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleClickSendMessage = async () => {
    for( let i=0; i < 3; i++) {
    //for( let i=0; i < routeMap.length; i++) {
      if (routeAppContext.id == "5") {
        carInfoData_ka4_004.data.route_id = "5"
        carInfoData_ka4_004.data.vehicle_id = "ka4_4"
        carInfoData_ka4_004.data.speed = Math.floor(Math.random() * 100);
        carInfoData_ka4_004.data.latitude = Number(routeMap[i].station.site_lat);
        carInfoData_ka4_004.data.longitude = Number(routeMap[i].station.site_lng);
        sendJsonMessage(carInfoData_ka4_004)
      } 
      if (routeAppContext.id == "4") {
        carInfoData_ka4_004.data.route_id = "4"
        carInfoData_ka4_004.data.vehicle_id = "ka4_4"
        carInfoData_ka4_004.data.speed = Math.floor(Math.random() * 100);
        carInfoData_ka4_004.data.latitude = Number(routeMap[i].station.site_lat);
        carInfoData_ka4_004.data.longitude = Number(routeMap[i].station.site_lng);
        sendJsonMessage(carInfoData_ka4_004)
      } 
      if (routeAppContext.id == "3") {
        carInfoData_kona_001.data.route_id = "3"
        carInfoData_kona_001.data.vehicle_id = "kona_1"
        carInfoData_kona_001.data.speed = Math.floor(Math.random() * 100);
        carInfoData_kona_001.data.latitude = Number(routeMap[i].station.site_lat);
        carInfoData_kona_001.data.longitude = Number(routeMap[i].station.site_lng);
        sendJsonMessage(carInfoData_kona_001)
      } else {
        carInfoData_kona_003.data.route_id = "1"
        carInfoData_kona_003.data.vehicle_id = "kona_3"
        carInfoData_kona_003.data.speed = Math.floor(Math.random() * 100);
        carInfoData_kona_003.data.latitude = Number(routeMap[i].station.site_lat);
        carInfoData_kona_003.data.longitude = Number(routeMap[i].station.site_lng);
        sendJsonMessage(carInfoData_kona_003)
      }

      await sleep(3000);
    }
  }

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  function onChangeRoute(event) {
    const result = routeOptions.find(option => Number(option.id) === Number(event.target.value));
    if (result) {
      setRouteAppContext(result.route);
      setRouteId(prev => result.route.id);
      setPosition({ lat: Number(result.route.ref_lat), lng: Number(result.route.ref_lng) });
      setFlyToPosition({ lat: Number(result.route.ref_lat), lng: Number(result.route.ref_lng) });
      setOnVehicleId('');
      axiosInstance.get('/robotaxi/routemap/',{
          params: {id: result.route.id}
      }).then(function(response) {
          setRouteMap(response.data);
      }).catch(function (error) {
          console.log(error);
      });

      axiosInstance.get('/robotaxi/robocar/', {
        params: { route_id: result.route.id }
      }).then(function (response) {
        setCarList(response.data);
        setCarAppContext(response.data[0]);
        response.data.map((data) => {
          setCarOptions(carOptions => [...carOptions, { name: data.carname, id: data.id, car: data }]);
        });
      }).catch(function (error) {
        console.log(error);
      });

      //initialize carinfo
      if (result.route.id == "5") {
        addCarInfoHandler(carInfoData_ka4_004.data);
      } else if (result.route.id == "4") {
        addCarInfoHandler(carInfoData_ka4_004.data);
      } else if (result.route.id == "3") {
        addCarInfoHandler(carInfoData_kona_001.data);
      } else {
        addCarInfoHandler(carInfoData_kona_003.data);
      }
    }
  };

  function FlyMapTo() {
    useEffect(() => {
        if (flyToPosition.lat !== 0) {
          map.flyTo(flyToPosition, 17)
          //정류장과 현차량위치까지의 거리 측정
          var distance_data = 100;
          const station_id = 0;
          //console.log(flyToPosition);
          if (isPopStationInfo) {
            routeMap.map((data) => {
              //console.log(data.station);
              distance_data = getDistanceFromLatLonInMeters(
                flyToPosition.lat,
                flyToPosition.lng,
                Number(data.station.site_lat),
                Number(data.station.site_lng)
              );

              if (distance_data <= 50) {
                setInFlag(true)
                setInStation(Number(data.station.id))
              }

              if ((distance_data > 50) && (inStation == Number(data.station.id))) {
                setInFlag(false)
                setGetFlag(false);
                setModalShow(false);
                //console.log('현재 위치에서 대상 위치까지의 거리는 50m 이내에 없습니다.');
              }

              if (inFlag && !getFlag && (inStation == Number(data.station.id))) {
                console.log('현재 위치에서 대상 위치까지의 거리는 50m 이내에 있습니다.');
                console.log(data.station.stationname);
                setStationName(data.station.stationname);
                axiosInstance.get('/adrt/routestops/', {
                  params: { routemap_id: data.id, route_id: data.route.id, routesection_id: data.routesection.id, station_id: data.station.id, vehicle_id: carAppContext.vehicle_id}
                }).then(function (response) {
                  //console.log(response.data);
                  setGetFlag(true);
                  setRouteStops(response.data);
                  setModalShow(true);
                }).catch(function (error) {
                  //console.log(error);
                });
              }
            })
          }
          
        }
    }, [flyToPosition])
    return null
  }

  const getDistanceFromLatLonInMeters = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // 지구의 반지름 (단위: km)
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c * 1000; // 거리를 미터로 변환
    return d;
  };

  const deg2rad = deg => {
    return deg * (Math.PI / 180);
  };

  const handleZoomEnd = (e) => {
    //console.log('Map zoom level:', e.target.getZoom());
  };

  function onSelectCar(event) {
    const result = carOptions.find(option => Number(option.id) === Number(event.currentTarget.id));
    setCarAppContext(result.car);
    setIsPopVideoInfo(false);
  }

  return (
    <Stack gap={1}>
      <div className="bg-light border">
        <Container fluid>
          <Row>
            <Col xs={12} md={4}>
                <DropdownSelector
                  labelName={t('FMS_ROUTE')}
                  options={routeOptions}
                  defaultValue={routeId}
                  onChange={onChangeRoute}
                />
            </Col>
            { isAuthenticated ? 
              <>
                <Col xs={12} md="auto">
                  <ToggleSwitch label={t('FMS_CARVIEW')} isChecked={isSwitchFlyToMap} onChange={onSwitchFlyToMap}/>
                </Col>
                {/*
                <Col xs={12} md="auto">
                  <ToggleSwitch label={t('FMS_CARVIDEO')} isChecked={isPopVideoInfo} onChange={onSwitchPopVideoInfo}/>
                </Col>
                */}
                <Col xs={12} md="auto">
                  <ToggleSwitch label={t('FMS_INFOVIEW')} isChecked={isPopStationInfo} onChange={onSwitchPopStationInfo}/>
                </Col>
                <Col xs={12} md="auto" style={{textAlign: 'center',}}>
                  <Button
                    onClick={handleClickSendMessage}
                    disabled={readyState !== ReadyState.OPEN}
                    size="sm"
                  >
                    {t('FMS_SENDLOCATION')}
                  </Button>
                </Col>
              </> : <Col></Col> }
          </Row>
        </Container>
      </div>
      <div className="bg-light border">
        <Container fluid>
          <Row>
            <Stack direction="horizontal" gap={2}>
              <Button variant="light" disabled>{t('FMS_INFOCAR')}</Button>
              {carList && carList.map((data) => {
                  return (
                    <ToggleButton
                      id={data.id}
                      key={data.id}
                      type="checkbox"
                      variant="outline-primary"
                      value={data.vehicle_id}
                      checked={data.id == carAppContext.id}
                      onChange={(e) => onSelectCar(e)}
                    >
                     <b>{data.carname}</b>
                      { (onVehicleId == data.vehicle_id) ? (
                        <Badge bg="success" className="ms-2">ON</Badge>
                      ) : (
                        <Badge bg="danger" className="ms-2">OFF</Badge>
                      )
                      }
                    </ToggleButton>
                  
                    );
              })}
            </Stack>
          </Row>
        </Container>
      </div>
      <div className="bg-light border">
        {isLoading ? <LoadingSpinner /> : ""}
        <MapContainer
          center={{
            lat: Number(routeAppContext.ref_lat),
            lng: Number(routeAppContext.ref_lng),
          }}
          zoom={Number(routeAppContext.defalt_zoom)}
          style={{ height: '85vh', width: '100%' }}
          ref={setMap}
          zoomControl={false}
          scrollWheelZoom={true}
        >
          <Layers routeMap={routeMap} carInfo={carInfo} carInfoLast={carInfoLast} map={map} isPopVideoInfo={isPopVideoInfo} />
          <ZoomControl position='topright' />
          <ZoomEventHandlers handleZoomEnd={handleZoomEnd} />
          {isSwitchFlyToMap && <FlyMapTo /> }
        </MapContainer>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        stationName={stationName}
        routeStops={routeStops}
      />
    </Stack>
  )
}

const ZoomEventHandlers = ({ handleZoomEnd }) => {
  useMapEvent('zoomend', handleZoomEnd);
  return null;
};

export default MapLeaflet