import React, { useState, useEffect } from "react";
import { Nav, Navbar, Stack } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import "./Settings.module.css";
import { useTranslation } from 'react-i18next';
import { useAppContext } from "../libs/contextLib";

function Settings() {
  const { t } = useTranslation();
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [routes, setRoutes] = useState([]);
  const { currentLocation, setCurrentLocation } = useAppContext();
  setCurrentLocation('/settings');
  
  const cities = [
    {name: 'Mumbai, India', position: {lat: 19.07283,lng: 72.88261}},
    {name: 'Bhubaneshwar, India', position: {lat: 20.296059,lng: 85.824539}},
    {name: 'Lucknow, India', position: {lat: 26.850000,lng: 80.949997}},
    {name: 'Delhi, India', position: {lat: 28.610001,lng: 77.230003}},
    {name: 'Amaravati, India', position: {lat: 14.685564,lng: 77.595406}},
    {name: 'Itanagar, India', position: {lat: 27.1,lng: 93.62}},
    {name: 'Guwahati, India', position: {lat: 26.148043,lng: 91.731377}},
    {name: 'Patna, India', position: {lat: 25.612677,lng: 85.158875}},
    {name: 'Chandigarh, India', position: {lat: 30.741482,lng: 76.768066}},
    {name: 'Rome, Italy', position: {lat: 41.890251,lng: 12.492373}},
    {name: 'London, UK', position: {lat: 51.509865,lng: -.118092}},
    {name: 'Madrid, Spain', position: {lat: 40.416775,lng: -3.703790}},
    {name: 'Athens, Greece', position: {lat: 37.983810,lng: 23.727539}},  
    {name: 'Tokyo, Japan', position: {lat: 35.652832,lng: 139.839478}},
  ]

  useEffect(() => {
    async function onLoad() {
      try {
        axiosInstance.get('/robotaxi/route/').then(function(response) {
            setRoutes(response.data);
        }).catch(function (error) {
          if (error && error.response && error.response.status) {
            if ( error.response.status === 444 ) {
              onError(error.response.data.message);
            } else {
              onError(error);
            }
          } else {
            onError(error);
          }
        }); 
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, []);
  
  function changeMapCenter(e) {
    const index = e.target.value
    console.log(cities[index]['position'])
    console.log(cities[index]['name'])
    //this.forceUpdate();
   };

  return (
  <Stack gap={3}>
      <div className="bg-light border titlesubject">
        Settings
      </div>
      <form className="form">
        <select onChange={changeMapCenter}>
          {cities.map((city, index) => {
            return (
              <option value={index}>{city.name}</option>
            )
          })}
        </select>
      </form>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default Settings;