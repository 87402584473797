import React, { useMemo, useState, useEffect } from "react";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import { useTranslation } from 'react-i18next';
import {
    MRT_EditActionButtons,
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
//Import Material React Table Translations
import { MRT_Localization_KO } from 'material-react-table/locales/ko';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Checkbox,
    Tooltip,
    FormControlLabel,
    Typography,
    InputLabel,
    useTheme
} from '@mui/material';

import {
    QueryClient,
    QueryClientProvider,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import TableDatePicker from "./TableDatePicker";
import * as dateFns from "date-fns";

const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
});

function AdrtReservation() {
    const { t } = useTranslation();
    const [validationErrors, setValidationErrors] = useState({});
    const [startDate, setStartDate] = useState(new Date());

    //call READ hook
    const {
        data: fetchedreservations = [],
        isError: isLoadingreservationsError,
        isFetching: isFetchingreservations,
        isLoading: isLoadingreservations,
        refetch
    } = useGetAdrtReservation();

    const columns = useMemo(
        () => [
            {
                accessorKey: 'allotid', //simple recommended way to define a column
                header: '배차ID',//optional custom props
                size: 25,
            },
            {
                accessorKey: 'startdttm', //simple recommended way to define a column
                header: '배차일시',//optional custom props
                size: 150,
            },
            {
                accessorKey: 'routeid', //simple recommended way to define a column
                header: '경로ID',//optional custom props
                size: 25,
            },
            {
                accessorKey: 'carnum', //simple recommended way to define a column
                header: '차량번호',//optional custom props
                size: 100,
            },
            {
                accessorKey: 'userid', //simple recommended way to define a column
                header: '사용자ID',//optional custom props
                size: 25,
            },
            {
                accessorKey: 'phonenum', //simple recommended way to define a column
                header: '전화번호',//optional custom props
                size: 100,
            },
            {
                accessorKey: 'seatnum', //simple recommended way to define a column
                header: '자리번호',//optional custom props
                size: 25,
            },
            {
                accessorKey: 'resdttm', //simple recommended way to define a column
                header: '예약일시',//optional custom props
            },
            {
                accessorKey: 'onstopnm', //simple recommended way to define a column
                header: '출발정류장',//optional custom props
            },
            {
                accessorKey: 'ondttm', //simple recommended way to define a column
                header: '출발일시',//optional custom props
            },
            {
                accessorKey: 'offstopnm', //simple recommended way to define a column
                header: '도착정류장',//optional custom props
            },
            {
                accessorKey: 'offdttm', //simple recommended way to define a column
                header: '도착일시',//optional custom props
            },
            {
                accessorKey: 'resstatusnm', //simple recommended way to define a column
                header: '상태',//optional custom props
            },
        ],
        [validationErrors],
    );

    const handleExportData = () => {
        const csv = generateCsv(csvConfig)(fetchedreservations);
        download(csvConfig)(csv);
    };

    const table = useMaterialReactTable({
        columns,
        data: fetchedreservations,
        initialState: {
            density: 'compact',
            pagination: { pageSize: 10, pageIndex: 0 }
        },
        muiTableHeadCellProps: {
            //simple styling with the `sx` prop, works just like a style prop in this example
            sx: {
                fontWeight: 'bold',
                fontSize: '14px',
                backgroundColor: '#D3EFBD',
            },
        },
        enableRowSelection: true,
        columnFilterDisplayMode: 'popover',
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                }}
            >
                <Button
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    onClick={handleExportData}
                    startIcon={<FileDownloadIcon />}
                >
                    Export All Data
                </Button>
            </Box>
        ),
    });

    //READ hook (get users from api)
    function useGetAdrtReservation() {
        return useQuery({
            queryKey: ['reservations'],
            queryFn: async () => {
                //console.log(dateFns.format(startDate, "yyyy-MM-dd"));
                const response = await axiosInstance.get('/adrt/allot/resinfo/', {
                    params: {
                        s_date: dateFns.format(startDate, "yyyy-MM-dd")
                    }
                });
                //console.log(response.data);
                return JSON.parse(response.data[0]);
            },
        });
    }

    async function onChangeStartDate(event) {
        setStartDate(startDate => event);
    }

    useEffect(() => {
        refetch();
    }, [startDate]);

    return (
        <Box m="10px">
            <Box sx={{ display: 'flex' }}>
                <InputLabel>조회일자 : </InputLabel>

                <TableDatePicker
                    wrapperClassName="datepicker"
                    selected={startDate}
                    onChange={onChangeStartDate}
                />
            </Box>
            <Box
                mt="10px"
            >
                <MaterialReactTable table={table} />
            </Box>

        </Box>);
}

export default AdrtReservation;