import React, { useCallback, useEffect, useState, useRef, useReducer } from 'react';
import { Nav, Navbar, Stack, Form, Row, Col, Button } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import MapAutoDrive from "../map/MapAutoDrive";
import config from "../config";
import useWebSocket, { ReadyState } from 'react-use-websocket';

export default function DrivingMap() {
    const [isPaused, setPause] = useState(false);
    const [message, setMessage] = useState('Message:')
    const [jsonData, setJsonData] = useState("")
    //const ws = useRef<WebSocket>(new WebSocket(`${config.SOCKET_URL}/api/ws`))

    //Public API that will echo messages sent to it back to the client
    const [socketUrl, setSocketUrl] = useState(`${config.SOCKET_URL}/ws`);
    const [messageHistory, setMessageHistory] = useState([]);

    const {
        sendMessage,
        sendJsonMessage,
        lastMessage,
        lastJsonMessage,
        readyState,
        getWebSocket
    } = useWebSocket(socketUrl, {
        onOpen: () => console.log('opened'),
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => true,
    });

    useEffect(() => {
        if (lastMessage !== null) {
            console.log(lastMessage);
            const json = JSON.parse(lastMessage.data);
            switch (json.type) {
                case 'mapHD':
                    // 정밀지도 그리기
                    //this.drawMapHD(json.data);
                    //console.log(JSON.stringify(json.type));
                    setMessage("Get message from server: " + json.type);
                    setJsonData(json.data);
                    break;

                case 'map':
                    // J2735 MapData 그리기
                    //this.drawMapData(json.data);
                    setMessage("Get message from server: " + json.type);
                    break;

                case 'vehicle':
                    // 자동차 위치 표시
                    //this.drawVehicle(json.data);
                    setMessage("Get message from server: " + json.type);
                    break;
            }    
        }
    }, [lastMessage]);

    /*
    useEffect(() => {
        if (!ws.current) {
            ws.current = new WebSocket(`${config.SOCKET_URL}/api/ws`);
            ws.current.onopen = () => console.log("ws opened");
            ws.current.onclose = () => console.log("ws closed");
            ws.current.onerror = (error) => console.log("connection error")
        }
        return () => {
            console.log("clean up");
            ws.current.close();
        };
    }, []);
  
    useEffect(() => {
        if (!ws.current) return;
  
        ws.current.onmessage = (e) => {
        try {
            const json = JSON.parse(e.data);
            //console.log("Socket On message:" + e.data);
            switch (json.type) {
                case 'mapHD':
                    // 정밀지도 그리기
                    //this.drawMapHD(json.data);
                    //console.log(JSON.stringify(json.type));
                    setMessage("Get message from server: " + json.type);
                    setJsonData(json.data);
                    break;
    
                case 'map':
                    // J2735 MapData 그리기
                    //this.drawMapData(json.data);
                    setMessage("Get message from server: " + json.type);
                    break;
    
                case 'vehicle':
                    // 자동차 위치 표시
                    //this.drawVehicle(json.data);
                    setMessage("Get message from server: " + json.type);
                    break;
            }
            } catch (err) {
                console.log(err);
            }
        };
    }, [isPaused])
    */
    const clickHandler = () => {
        setPause(!isPaused);
        console.log(jsonData);
    }
    return (
    <Stack gap={3}>
        <div className="bg-light border">
            <Nav>
            <LinkContainer to="/drivingcontrol">
                        <Nav.Link>자율주행관제</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/drivingmap">
                        <Nav.Link>자율주행맵</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/mapsocket">
                        <Nav.Link>웹소켓테스트</Nav.Link>
                    </LinkContainer>
            </Nav>
        </div>
        <div>
            <button onClick={clickHandler}>
                {isPaused ? "Resume" : "Pause"}
            </button>
            {message}
        </div>
        <div className="bg-light border">
            <MapAutoDrive data={jsonData} />
        </div>
        <div>
            <br /><br /><br />
        </div>
    </Stack>
    );
}